import FooterBanner from "./FooterBanner";
import FooterFeatured from "./FooterFeatured";
import FooterMiddle from "./FooterMiddle";

const Footer = () => {
  let disableId = document?.getElementById(`disableImgId`);
  disableId?.addEventListener("contextmenu", (ev) => {
    ev?.preventDefault(); // this will prevent browser default behavior
  });
  return (
    <footer
      className="main"
      //  id="disableImgId"
    >
      <FooterBanner />
      <FooterFeatured />

      <FooterMiddle />
      <div className="container pb-30">
        <div className="row align-items-center">
          <div className="col-12 mb-30">
            <div className="footer-bottom" />
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6">
            <p className="font-sm mb-0">
              © {new Date().getFullYear()} ,{" "}
              <strong className="text-brand">
                <a>Silverline Wholesale , Inc.</a>
              </strong>
            </p>
          </div>
          <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block d-xl-inline-flex">
            <div className="hotline d-lg-inline-flex mr-30">
              <img src="/images/theme/icons/phone-call-2.svg" alt="hotline" />
              <p>
                (630) 785-6838<span>Working 09:00 AM - 05:30 PM</span>
              </p>
            </div>
            {/* <div className="hotline d-lg-inline-flex">
              <img src="/images/theme/icons/phone-call-2.svg" alt="hotline" />
              <p>
                (630) 785-6838
                <span>24/7 Support Center</span>
              </p>
            </div> */}
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
            <div className="mobile-social-icon">
              {/* <h6>Follow Us</h6> */}
              {/* <a href="" onClick={(e) => e.preventDefault()}>
                                <img src="/images/theme/icons/icon-facebook-white.svg" alt="" />
                            </a>
                            <a href="" onClick={(e) => e.preventDefault()}>
                                <img src="/images/theme/icons/icon-twitter-white.svg" alt="" />
                            </a>
                            <a href="" onClick={(e) => e.preventDefault()}>
                                <img
                                    src="/images/theme/icons/icon-instagram-white.svg"
                                    alt=""
                                />
                            </a>
                            <a href="" onClick={(e) => e.preventDefault()}>
                                <img
                                    src="/images/theme/icons/icon-pinterest-white.svg"
                                    alt=""
                                />
                            </a>
                            <a href="" onClick={(e) => e.preventDefault()}>
                                <img src="/images/theme/icons/icon-youtube-white.svg" alt="" />
                            </a> */}
            </div>
            <p className="font-sm">
              {/* Up to 15% discount on your first subscribe */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
