const FooterFeatured = () => {
    return (
      <section className="featured section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div
                className="banner-left-icon d-flex align-items-center wow fadeIn animated"
                style={{ maxHeight: "114px" }}
              >
                <div className="banner-icon">
                  <img src="/images/theme/icons/icon-1.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Best prices &amp; offers</h3>
                  {/* <p>Orders $50 or more</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                <div className="banner-icon">
                  <img src="/images/theme/icons/icon-2.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Free delivery</h3>
                  {/* <p>24/7 amazing services</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                <div className="banner-icon">
                  <img src="/images/theme/icons/icon-3.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Great daily deal</h3>
                  <p>When you sign up</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                <div className="banner-icon">
                  <img src="/images/theme/icons/icon-4.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Wide assortment</h3>
                  <p>Mega Discounts</p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                        <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                            <div className="banner-icon">
                                <img src="/images/theme/icons/icon-5.svg" alt="" />
                            </div>
                            <div className="banner-text">
                                <h3 className="icon-box-title">Easy returns</h3>
                                <p>Within 30 days</p>
                            </div>
                        </div>
                    </div> */}
            {/* <div className="col-lg-3d-xl-none">
              <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                <div className="banner-icon">
                  <img src="/images/theme/icons/icon-6.svg" alt="" />
                </div>
                <div className="banner-text">
                  <h3 className="icon-box-title">Safe delivery</h3>
                  <p>Within 30 days</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
}

export default FooterFeatured;