import React from "react";

const CookiesModal = ({ onClick, acceptedClicked }) => {
  let widthOfPage = window.innerWidth;

  return (
    <div
      className="cookie-overlay p-4 "
      style={{ width: widthOfPage < 700 && "280px" }}
    >
      <div className="d-flex">
        <div className="mr-3" style={{ width: widthOfPage < 700 && "190px" }}>
          We use cookies to improve your website experience.
        </div>
        {/* <img src="/close.png" className="close-cookies" /> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          height: "70px",
          marginTop: "15px",
        }}
      >
        <button
          className="btn m-2 btn-fill-out btn-block  font-weight-bold cookie-button"
          onClick={onClick}
        >
          Learn More
        </button>
        <button
          className="btn m-2 btn-fill-out btn-block  font-weight-bold cookie-button"
          onClick={acceptedClicked}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookiesModal;
