import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { register } from "../../services/AuthService";
import { getPOSStates } from "../../services/StateService";
import { getAllCountry } from "../../services/CountryService";
import { AppContext } from "../../utils/AppProvider";
import { randomSecurityCode } from "../../utils/constant";
import ConfirmationPopupModal from "../ConfirmationPopupModal";
import { useFormik } from "formik";
import { object, ref, string } from "yup";
import _, { isEmpty } from "lodash";

// import { getCustomerCategories,getPosShipVias,getPosPaymentTypes } from "../../services/CustomerService";

const Register = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [passwordType, setpasswordType] = useState(false);
  const [states, setStates] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [isAgree, setIsAgree] = useState(false);
  const [confirmationPopup, setconfirmationPopup] = useState(false);
  const [billingAddressCheckbox, setbillingAddressCheckbox] = useState(false);
  const [newsletter_subscribe, setnewsletter_subscribe] = useState(true);
  const [incorrectPassPopup, setincorrectPassPopup] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  // const [shipVia, setShipVia] = useState([]);
  // const [paymentType, setPaymentType] = useState([]);
  // const [customerCategory, setCustomerCategory] = useState([]);
  // const [customer, setCustomer] = useState({
  //   name_prefix: "Mr.",
  //   name: "",
  //   email: "",
  //   alt_email: "",
  //   username: "",
  //   company_name: "",
  //   address: "",
  //   pincode: "",
  //   city: "",
  //   country_id: "2",
  //   state_id: "",
  //   tax_id: "",
  //   tax_expiry_date: "",
  //   tax_certificate: "",
  //   password: "",
  //   confirm_password: "",
  //   mobile: "",
  //   phone: "",
  //   dba: "",
  //   manager_number: "",
  //   fax: "",
  //   tob_license_no: "",
  //   tob_expiry_date: "",
  //   tob_certificate: "",
  //   code: "",
  //   // billing_street: "",
  //   billing_city: "",
  //   billing_address: "",
  //   billing_state: "",
  //   billing_zip: "",
  // });
  const myRef = useRef(null);
  // const usernameRegex = /^[a-zA-Z0-9]*$/;

  const { setIsAuthenticated } = useContext(AppContext);
  let date = new Date();
  date =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getCountry();

    // getPosShipVia();
    // getPosPaymentType();
    // getCustomerCategory();
    setSecurityCode(randomSecurityCode());
  }, []);

  const getPOSState = async (id) => {
    if (id != "") {
      await getPOSStates(id)
        .then((response) => {
          setStates(response.data.data);
        })
        .catch((error) => {});
    } else {
      setStates([]);
    }
  };
  // const getPosShipVia = async () => {
  //     await getPosShipVias().then((response) => {
  //         setShipVia(response.data.data);
  //     }).catch((error) => {
  //         console.log("Error:", error);
  //     });
  // }
  // const getPosPaymentType = async () => {
  //     await getPosPaymentTypes().then((response) => {
  //         setPaymentType(response.data.data);
  //     }).catch((error) => {
  //         console.log("Error:", error);
  //     });
  // }
  // const getCustomerCategory = async () => {
  //     await getCustomerCategories().then((response) => {
  //         setCustomerCategory(response.data.data);
  //     }).catch((error) => {
  //         console.log("Error:", error);
  //     });
  // }
  const getCountry = async () => {
    await getAllCountry()
      .then((response) => {
        setCountry(response.data);
        getPOSState(values?.country_id);
      })
      .catch((error) => {});
  };

  const handleInputChange = async (e) => {
    let { name, value } = e.target;
    // if (name == "country_id" && values.state_id != value) {
    //   // getPOSState(value);
    // }

    if (
      name == "phone" ||
      name == "mobile" ||
      name == "manager_number" ||
      name == "fax"
    ) {
      if (value != null) {
        var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        value = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        setFieldValue(name, value);
      }
    }

    // setCustomer({ ...values, [name]: value });

    if (name == "tax_certificate") {
      setFieldValue("tax_certificate", e?.target?.files[0]);

      return;
    }
    if (name == "tob_certificate") {
      setFieldValue("tob_certificate", e?.target?.files[0]);
    }
  };

  const RegisterSchema = object().shape({
    username: string()
      .required("Please enter username.")
      .trim("Please enter username.")
      .matches(
        /^[a-zA-Z0-9]*$/,
        "Username should not be contain any special character or any space. "
      )
      .min(6, "Username Must be between 6 to 12 characters long.")
      .max(12, "Username Must be between 6 to 12 characters long."),

    name: string().required("Please enter name.").trim("Please enter name."),

    email: string()
      .required("Please enter email.")
      .trim("Please enter email.")
      .email("Invalid Email Format. Please try again."),

    company_name: string()
      .required("Please enter company name.")
      .trim("Please enter company name."),

    password: string()
      .required("Please enter password.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one lowercase letter or one uppercase letter, one digit, one special character, and be at least 8 characters long."
      )
      .trim("Please enter password."),

    confirm_password: string()
      .required("Please enter confirm password.")
      .trim("Please enter confirm password.")
      .oneOf(
        [ref("password")],
        "Password and confirm password does not match."
      ),

    address: string()
      .required("Please enter address.")
      .trim("Please enter address."),

    pincode: string().required("Please enter zip.").trim("Please enter zip."),

    mobile: string()
      .required("Please enter Primary contact.")
      .trim("Please enter Primary contact."),

    country_id: string()
      .required("Please enter country.")
      .trim("Please enter country."),

    state_id: string()
      .required("Please select state.")
      .trim("Please select state."),

    city: string().required("Please enter city.").trim("Please enter city."),

    billing_city: string()
      .required("Please enter Billing city .")
      .trim("Please enter Billing city."),

    billing_address: string()
      .required("Please enter Billing Address.")
      .trim("Please enter Billing Address."),

    billing_state: string()
      .required("Please enter Billing State.")
      .trim("Please enter Billing State."),

    billing_zip: string()
      .required("Please enter Billing Zip.")
      .trim("Please enter Billing Zip."),

    tax_id: string()
      .required("Please enter Tax id.")
      .trim("Please enter Tax id."),
  });

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: RegisterSchema,

    initialValues: {
      // name_prefix: "Mr.",
      name: "",
      email: "",
      alt_email: "",
      username: "",
      company_name: "",
      address: "",
      pincode: "",
      city: "",
      country_id: "2",
      state_id: "",
      tax_id: "",
      tax_expiry_date: "",
      tax_certificate: "",
      password: "",
      confirm_password: "",
      mobile: "",
      phone: "",
      dba: "",
      manager_number: "",
      fax: "",
      tob_license_no: "",
      tob_expiry_date: "",
      tob_certificate: "",
      code: "",
      // billing_street: "",
      billing_city: "",
      billing_address: "",
      billing_state: "",
      billing_zip: "",
    },
    onSubmit: async (values, { resetForm }) => {
      submitClicked();
    },

    onReset: () => {},
  });

  const scrollUpDiv = () => {
    handleSubmit();
    if (
      !_.isEmpty(values?.billing_city) ||
      !_.isEmpty(values?.billing_address) ||
      !_.isEmpty(values?.billing_zip) ||
      !_.isEmpty(values?.billing_state)
    ) {
      // myRef.current.scrollIntoView();
    }
  };

  const submitClicked = async () => {
    // e.preventDefault();
    if (validateInput()) {
      setisLoading(true);

      let mobilenumber = values.mobile.replace(/\D/g, "");
      let phonenumber = values.phone?.replace(/\D/g, "");
      let manager_number = values.manager_number?.replace(/\D/g, "");
      var fax = values.fax?.replace(/\D/g, "");
      let formdata = new FormData();
      // formdata.append("name_prefix", values.name_prefix);
      formdata.append("name", values.name);
      formdata.append("email", values.email);
      formdata.append("alt_email", values.alt_email);
      formdata.append("username", values.username);
      formdata.append("company_name", values.company_name);
      formdata.append("address", values.address);
      formdata.append("pincode", values.pincode);
      formdata.append("city", values.city);
      formdata.append("country_id", values.country_id);
      formdata.append("state_id", values.state_id);
      formdata.append("tax_id", values.tax_id);
      formdata.append("tax_expiry_date", values.tax_expiry_date);
      formdata.append("tax_certificate", values.tax_certificate);
      formdata.append("password", values.password);
      formdata.append("mobile", mobilenumber);
      formdata.append("phone", phonenumber);
      formdata.append("manager_number", manager_number);
      formdata.append("dba", values.dba);
      formdata.append("tob_license_no", values.tob_license_no);
      formdata.append("tob_expiry_date", values.tob_expiry_date);
      formdata.append("tob_certificate", values.tob_certificate);
      // formdata.append("billing_street", values.billing_street);
      formdata.append("billing_city", values.billing_city);
      formdata.append("billing_address", values.billing_address);
      formdata.append("billing_state", values.billing_state);
      formdata.append("billing_zip", values.billing_zip);
      formdata.append("newsletter_subscribe", newsletter_subscribe ? 1 : 0);

      // formdata.append('payment_type',values.payment_type);
      // formdata.append('ship_via',values.ship_via);
      // formdata.append('cc_tax_id',values.cc_tax_id);
      formdata.append("fax", fax);

      await register(formdata)
        .then((response) => {
          toast("Customer Registered Successfully", { type: "success" });
          setisLoading(false);
          setconfirmationPopup(true);
          setincorrectPassPopup(false);
          seterrorMessage("");
        })
        .catch((error) => {
          setisLoading(false);
          setincorrectPassPopup(true);
          seterrorMessage(
            error?.response?.data?.error ||
              error?.response?.data?.message ||
              "Something went wrong,Please try again"
          );

          // if (error?.response?.status == 422)
          //   toast(error.response.data.error, { type: "error" });
          // else if (error?.response?.status == 500)
          //   toast(error.response.data.message, { type: "error" });
          // else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    if (values.tax_id != "" || values.tax_expiry_date != "") {
      if (values.tax_id != "" && values.tax_expiry_date != "") {
      } else {
        // if (values.tax_certificate == "") {
        //   toast("Please Select Tax certificate.", {
        //     type: "error",
        //   });
        //   return false;
        // }
      }
    }

    if (!isAgree) {
      // toast("Please accept the terms and conditions.", {
      //   type: "error",
      // });
       setincorrectPassPopup(true);
       seterrorMessage(
         "Please accept the terms and conditions."
       );
      return false;
    }

    if (values.code != securityCode) {
      // toast("Security code does not match", {
      //   type: "error",
      // });
      setincorrectPassPopup(true);
      seterrorMessage("Security code does not match");
      
      return false;
    }

    return true;
  };

  const billingAddressToggle = (value) => {
    if (value) {
      setbillingAddressCheckbox(true);
      setFieldValue("billing_city", values?.city);
      setFieldValue("billing_address", values?.address);
      setFieldValue("billing_zip", values?.pincode);
      setFieldValue("billing_state", values?.state_id);
    } else {
      setbillingAddressCheckbox(false);
      setFieldValue("billing_city", "");
      setFieldValue("billing_address", "");
      setFieldValue("billing_zip", "");
      setFieldValue("billing_state", "");
    }
  };

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Registration
          </div>
        </div>
      </div>
      <div className="page-content register-page pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <form method="post">
                <div className="row">
                  <div className="heading_s1">
                    <h1 className="mb-5">Create an Account</h1>
                    <p className="mb-30 login-hover">
                      Already have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                  <div className="login_wrap widget-taber-content background-white">
                    <p>Note: please fill in the * required information</p>
                    <div className="padding_eight_all w-100 bg-white d-lg-inline-flex">
                      <div className="col-xl-6 m-2">
                        {/* <div className="form-group">
                          <div className="row d-flex"> */}
                        {/* <div className="col-xl-3 col-3">
                              <select
                                className="form-select"
                                name="name_prefix"
                                value={values.name_prefix}
                                onChange={(e) => handleInputChange(e)}
                              >
                                <option value="Mr.">Mr.</option>
                                <option value="Ms.">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                                <option value="Miss.">Miss.</option>
                              </select>
                            </div> */}
                        {/* </div>
                        </div> */}
                        <div className="form-group" ref={myRef}>
                          <input
                            type="text"
                            required=""
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Name *"
                            name="name"
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={{
                              borderColor:
                                touched?.name && errors?.name
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.name && errors?.name && (
                            <span className="error-text">{errors.name}</span>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Email *"
                            style={{
                              borderColor:
                                touched?.email && errors?.email
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.email && errors?.email && (
                            <span className="error-text">{errors.email}</span>
                          )}
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="company_name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.company_name}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Company Name *"
                            style={{
                              borderColor:
                                touched?.company_name && errors?.company_name
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.company_name && errors?.company_name && (
                            <span className="error-text">
                              {errors.company_name}
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="fax"
                            id="fax"
                            onBlur={handleBlur}
                            // onChange={handleChange}
                            value={values.fax}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Fax Number"
                            className="input-border"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="dba"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.dba}
                            className="input-border"
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Doing Business As"
                          />
                        </div>
                        <div className="form-group">
                          <select
                            className="form-select"
                            name="country_id"
                            value={values.country_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={true}
                            // onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Select Shipping country *</option>
                            {country.map((country, index) => {
                              return (
                                <option key={index} value={country.id}>
                                  {" "}
                                  {country.country}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <select
                            className="form-select"
                            name="state_id"
                            value={values.state_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={{
                              borderColor:
                                touched?.state_id && errors?.state_id
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                            // onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Select Shipping state *</option>
                            {states.map((state, index) => {
                              return (
                                <option key={index} value={state.id}>
                                  {" "}
                                  {state.state}{" "}
                                </option>
                              );
                            })}
                          </select>
                          {touched?.state_id && errors?.state_id && (
                            <span className="error-text">
                              {errors.state_id}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Shipping city*"
                            style={{
                              borderColor:
                                touched?.city && errors?.city
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.city && errors?.city && (
                            <span className="error-text">{errors.city}</span>
                          )}
                        </div>
                        <div className="form-group">
                          <textarea
                            required=""
                            name="address"
                            rows={1}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Shipping address *"
                            style={{
                              borderColor:
                                touched?.address && errors?.address
                                  ? "red"
                                  : `var(--dark-container)`,
                              minHeight: "64px",
                            }}
                          />
                          {touched?.address && errors?.address && (
                            <span className="error-text">{errors.address}</span>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            required=""
                            name="pincode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.pincode}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Shipping zip *"
                            style={{
                              borderColor:
                                touched?.pincode && errors?.pincode
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.pincode && errors?.pincode && (
                            <span className="error-text">{errors.pincode}</span>
                          )}
                        </div>
                        <div
                          className="login_footer form-group  "
                          style={{ marginBottom: "7px" }}
                        >
                          <div className="chek-form">
                            <div className="custome-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={billingAddressCheckbox}
                                id="billingAddressCheckbox"
                                onChange={(e) =>
                                  billingAddressToggle(e.target.checked)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="billingAddressCheckbox"
                              >
                                <span>
                                  Billing address is same as Shipping address
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <select
                            className="form-select"
                            name="country_id"
                            onBlur={handleBlur}
                            // onChange={handleChange}
                            value={values.country_id}
                            disabled={true}
                            // onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Select Billing country *</option>
                            {country.map((country, index) => {
                              return (
                                <option key={index} value={country.id}>
                                  {" "}
                                  {country.country}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <select
                            className="form-select"
                            name="billing_state"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.billing_state}
                            style={{
                              borderColor:
                                touched?.billing_state && errors?.billing_state
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                            // onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Select Billing State *</option>
                            {states.map((state, index) => {
                              return (
                                <option key={index} value={state.id}>
                                  {" "}
                                  {state.state}{" "}
                                </option>
                              );
                            })}
                          </select>
                          {touched?.billing_state && errors?.billing_state && (
                            <span className="error-text">
                              {errors.billing_state}
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="billing_city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.billing_city}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Billing city *"
                            style={{
                              borderColor:
                                touched?.billing_city && errors?.billing_city
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.billing_city && errors?.billing_city && (
                            <span className="error-text">
                              {errors.billing_city}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <textarea
                            required=""
                            name="billing_address"
                            rows={1}
                            value={values.billing_address}
                            // onChange={(e) => handleInputChange(e)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Billing address *"
                            style={{
                              borderColor:
                                touched?.billing_address &&
                                errors?.billing_address
                                  ? "red"
                                  : `var(--dark-container)`,
                              minHeight: "64px",
                            }}
                          />
                          {touched?.billing_address &&
                            errors?.billing_address && (
                              <span className="error-text">
                                {errors.billing_address}
                              </span>
                            )}
                        </div>
                        {/* <div className="form-group">
                          <input
                            name="billing_street"
                            value={values.billing_street}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Billing street "
                          />
                        </div> */}
                        <div className="form-group">
                          <input
                            type="number"
                            required=""
                            name="billing_zip"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.billing_zip}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Billing zip *"
                            style={{
                              borderColor:
                                touched?.billing_zip && errors?.billing_zip
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.billing_zip && errors?.billing_zip && (
                            <span className="error-text">
                              {errors.billing_zip}
                            </span>
                          )}
                        </div>
                        <h5 className="">NewsLetter :</h5>
                        <div className="d-flex">
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",
                              marginRight: "40px",
                              // marginTop: "21px",
                            }}
                          >
                            Subscribe:
                          </label>
                          <div
                            className="col-sm-10 d-flex"
                            style={{ gap: "20px" }}
                          >
                            <label
                              className="radio-inline d-flex"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                gap: "5px",
                                marginRight: "10px",
                              }}
                            >
                              <input
                                type="radio"
                                name="newsletter"
                                defaultChecked={true}
                                onChange={() => setnewsletter_subscribe(true)}
                                // value="1"
                                // checked="checked"
                              />
                              <span>Yes</span>
                            </label>
                            <label
                              className="radio-inline d-flex"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                gap: "5px",
                              }}
                            >
                              <input
                                type="radio"
                                name="newsletter"
                                onChange={() => setnewsletter_subscribe(false)}

                                //  value="0"
                              />

                              <span>No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 m-2">
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="username"
                            value={values.username}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Username *"
                            style={{
                              borderColor:
                                touched?.username && errors?.username
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.username && errors?.username && (
                            <span className="error-text">
                              {errors.username}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="alt_email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.alt_email}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Second email "
                            style={{
                              borderColor:
                                touched?.alt_email && errors?.alt_email
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.alt_email && errors?.alt_email && (
                            <span className="error-text">
                              {errors.alt_email}
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="mobile"
                            onBlur={handleBlur}
                            // onChange={handleChange}
                            id="mobile"
                            value={values.mobile}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Primary Contact#"
                            style={{
                              borderColor:
                                touched?.mobile && errors?.mobile
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.mobile && errors?.mobile && (
                            <span className="error-text">{errors.mobile}</span>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="phone"
                            onBlur={handleBlur}
                            // onChange={handleChange}
                            id="phone"
                            value={values.phone}
                            className="input-border"
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Secondary Contact#"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            required=""
                            type={passwordType ? "text" : "password"}
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Password *"
                            style={{
                              borderColor:
                                touched?.password && errors?.password
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          <img
                            onClick={() => setpasswordType(!passwordType)}
                            src={
                              "/images/theme/icons/" +
                              (passwordType
                                ? "icon-eye.svg"
                                : "icon-eye-off.svg")
                            }
                            className="show-password"
                          />
                          {touched?.password && errors?.password && (
                            <span className="error-text">
                              {errors.password}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            required=""
                            type={passwordType ? "text" : "password"}
                            name="confirm_password"
                            value={values.confirm_password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Confirm password *"
                            style={{
                              borderColor:
                                touched?.confirm_password &&
                                errors?.confirm_password
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          <img
                            onClick={() => setpasswordType(!passwordType)}
                            src={
                              "/images/theme/icons/" +
                              (passwordType
                                ? "icon-eye.svg"
                                : "icon-eye-off.svg")
                            }
                            className="show-password"
                            alt="show-password"
                          />
                          {touched?.confirm_password &&
                            errors?.confirm_password && (
                              <span className="error-text">
                                {errors.confirm_password}
                              </span>
                            )}
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="manager_number"
                            id="manager_number"
                            onBlur={handleBlur}
                            // onChange={handleChange}
                            value={values.manager_number}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Manager Number#"
                            className="input-border"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="tax_id"
                            value={values.tax_id}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Tax ID *"
                            style={{
                              borderColor:
                                touched?.tax_id && errors?.tax_id
                                  ? "red"
                                  : `var(--dark-container)`,
                            }}
                          />
                          {touched?.tax_id && errors?.tax_id && (
                            <span className="error-text">{errors.tax_id}</span>
                          )}
                        </div>
                        <label>Tax Expiry Date:</label>
                        <div className="form-group">
                          <input
                            type="date"
                            required=""
                            name="tax_expiry_date"
                            min={date}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.tax_expiry_date}
                            className="input-border"

                            // onChange={(e) => handleInputChange(e)}
                          />
                        </div>
                        <label>Tax Certificate:</label>
                        <div className="form-group">
                          <input
                            type="file"
                            required=""
                            accept=".jpg, .jpeg, .png, .pdf"
                            className="form-control form-control-lg input-border"
                            name="tax_certificate"
                            onBlur={handleBlur}
                            // onChange={handleChange}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Tax Certificate"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="tob_license_no"
                            value={values.tob_license_no}
                            // onChange={(e) => handleInputChange(e)}
                            placeholder="Tob License No"
                            className="input-border"
                          />
                        </div>
                        <label>Tobacco Expiry Date:</label>
                        <div className="form-group">
                          <input
                            type="date"
                            required=""
                            name="tob_expiry_date"
                            min={date}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.tob_expiry_date}
                            className="input-border"

                            // onChange={(e) => handleInputChange(e)}
                          />
                        </div>
                        <label>Tobacco Certificate:</label>
                        <div className="form-group">
                          <input
                            type="file"
                            required=""
                            className="form-control form-control-lg input-border"
                            name="tob_certificate"
                            onBlur={handleBlur}
                            // onChange={handleChange}

                            onChange={(e) => handleInputChange(e)}
                            placeholder="Tobacco Certificate"
                          />
                        </div>
                        {/* <div className="form-group">
                                            <select name="ship_via" id="ship_via" value={values.ship_via} onChange={(e) => handleInputChange(e)} className="form-control">
                                                <option value="0">Select Ship Via</option>
                                                {
                                                    shipVia.map((shipVia,index) =>{
                                                        return (
                                                            <option key={index} value={shipVia.id}> {shipVia.ship_type}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            </div>
                                            <div className="form-group">
                                            <select name="payment_type" id="payment_type" value={values.payment_type} onChange={(e) => handleInputChange(e)} className="form-control">
                                            <option value="0">Select Payment Type *</option>
                                                {
                                                    paymentType.map((payment,index) =>{
                                                        return (
                                                            <option key={index} value={payment.id}> {payment.payment_type}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            </div>
                                            <div className="form-group">
                                            <select name="cc_tax_id" id="cc_tax_id" value={values.cc_tax_id} onChange={(e) => handleInputChange(e)} className="form-control">
                                            <option value="0">Select Membership Type *</option>
                                                {
                                                    customerCategory.map((cc,index) =>{
                                                        return (
                                                            <option key={index} value={cc.cc_id}> {cc.cc_name}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            </div> */}
                        <div className="login_footer form-group">
                          <div className="chek-form">
                            <input
                              type="text"
                              required=""
                              name="code"
                              value={values.code}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              className="input-border"
                              // onChange={(e) => handleInputChange(e)}
                              placeholder="Security code *"
                            />
                          </div>
                          <span className="security-code">
                            {securityCode.split("").map((num, index) => {
                              return index == 0 ? (
                                <b key={index} className="text-new">
                                  {num}
                                </b>
                              ) : index == 1 ? (
                                <b key={index} className="text-hot">
                                  {num}
                                </b>
                              ) : index == 2 ? (
                                <b key={index} className="text-sale">
                                  {num}
                                </b>
                              ) : (
                                <b key={index} className="text-best">
                                  {num}
                                </b>
                              );
                            })}
                          </span>
                        </div>
                        <div className="login_footer form-group mb-50">
                          <div className="chek-form">
                            <div className="custome-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="checkbox"
                                id="exampleCheckbox12"
                                checked={isAgree}
                                onChange={(e) =>
                                  e.target.checked
                                    ? setIsAgree(true)
                                    : setIsAgree(false)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheckbox12"
                              >
                                <span>I agree to terms &amp; Policy.</span>
                              </label>
                            </div>
                          </div>
                          <Link to="/privacy-policy">
                            <i className="fi-rs-book-alt mr-5 text-muted" />
                            Lean more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <div className="text-center">
                      <div className="form-group mb-30">
                        <button
                          type="submit"
                          className="btn btn-fill-out btn-block hover-up font-weight-bold"
                          name="login"
                          onClick={(e) => {
                            e?.preventDefault();
                            scrollUpDiv();
                          }}
                        >
                          {!isLoading ? `Submit & Register` : "...Loading"}
                        </button>
                      </div>
                      <div className="d-flex justify-content-center">
                        <p className="font-xs text-muted col-lg-8">
                          <strong>Note:</strong>Your personal data will be used
                          to support your experience throughout this website, to
                          manage access to your account, and for other purposes
                          described in our privacy policy
                        </p>
                      </div>
                    </div>
                    <ConfirmationPopupModal
                      // title={"Customer Registered Successfully"}
                      content={`Thank you for your registration with Silver Line wholesale,You will be able to login into your account after we send you confirmation on your email`}
                      show={confirmationPopup}
                      isOk={true}
                      isRegister={true}
                      setShow={setconfirmationPopup}
                      confirmClicked={(e) => {
                        navigate("/login");
                      }}
                    />
                    <ConfirmationPopupModal
                      title={"ERROR"}
                      content={`${errorMessage}`}
                      show={incorrectPassPopup}
                      isOk={true}
                      setShow={setincorrectPassPopup}
                      confirmClicked={(e) => {
                        setincorrectPassPopup(false);
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
