import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveCategories = async (text="", limit = "all") => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    // axios.get(`/customer/categories?search=${text}`,{headers:headers})
    axios
      .get(`/customer/categories?search=${text}&limit=${limit}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveSingleCategory = async (subcat) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/single-category?category=${subcat}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveBrands = async (cat) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/brands?category=0,${cat}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

function handleAuthFailure(error, reject) {
  if (
    error?.response.status === 401 &&
    error?.response.data.message === "Unauthenticated."
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("is_authenticated");
    if (window.location.pathname != "/login") {
      window.location.href = "/#/login";
    }
  } else {
    reject(error);
  }
}

export { retriveCategories, retriveSingleCategory, retriveBrands };
