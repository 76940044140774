import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  retriveAccountDetails,
  retriveAddreses,
} from "../../services/CustomerService";
import Navigation from "./Navigation";
import { getPOSStates } from "../../services/StateService";
import Loader from "../Loader";

const Address = () => {
  const [billingAdd, setBillingAdd] = useState({});
  const [states, setStates] = useState([]);
  const [shippingAdd, setShippingAdd] = useState({});
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    // getAddresses();
    getAccountDetails();
  }, []);

  // const getAddresses = async () => {
  //   await retriveAddreses()
  //     .then((response) => {
  //       if (response.data) {
  //         let data = response.data.data;

  //         let bill_add = {
  //           address: data.address,
  //           city: data.city,
  //           state_id: data.state_id,
  //           state_name: data.state.state,
  //           pincode: data.pincode,
  //         };

  //         // let shipp_add = {};
  //         // if (data.hasOwnProperty("addresses") && data.addresses.length > 0) {
  //         //   let temp = data.addresses[0];
  //         //   shipp_add = {
  //         //     address: temp.address,
  //         //     city: temp.city,
  //         //     state_id: temp.state_id,
  //         //     pincode: temp.pincode,
  //         //     state_name: temp.state?.state,
  //         //     shipp_add_id: temp.id,
  //         //   };
  //         // }

  //         setBillingAdd(bill_add);
  //         // setShippingAdd(shipp_add);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error", error.response);
  //       if (error?.response?.status == 422)
  //         toast(error.response.data.error, { type: "error" });
  //       else if (error?.response?.status == 500)
  //         toast(error.response.data.message, { type: "error" });
  //       else toast("Something went wrong", { type: "error" });
  //     });
  // };
  let obj = {
    address: "",
    city: "",
    state_id: "",
    state_name: "",
    pincode: "",
    isAddAddress: true,
  };

  const getAccountDetails = async () => {
    await retriveAccountDetails()
      .then((response) => {
        let data = response.data.data;
        let shipp_add = {};
        setisLoading(false);
        shipp_add = {
          address: data.address,
          city: data.city,
          state_id: data?.state_id,
          pincode: data.pincode,
          state_name: data?.state_id,
          shipp_add_id: data.id,
        };
        let bill_add = {
          address: data.billing_address,
          city: data.billing_city,
          state_id: data.billing_state,
          state_name: data.billing_state,
          pincode: data.billing_zip,
        };
        setBillingAdd(bill_add);
        setShippingAdd(shipp_add);
        getPOSState(
          data.country_id,
          data.state_id,
          shipp_add,
          data.billing_state,
          bill_add
        );
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getPOSState = async (id, state_id, data, billStateId, billData) => {
    if (id != "") {
      await getPOSStates(id)
        .then((response) => {
          setStates(response.data.data);
          let state;
          for (let a of response.data.data) {
            if (a?.id === state_id) {
              state = a?.state;
            }
          }
          setShippingAdd({ ...data, state_name: state });
          let bill_state;
          for (let a of response.data.data) {
            if (a?.id === billStateId) {
              bill_state = a?.state;
            }
          }
          setBillingAdd({ ...billData, state_name: bill_state });
        })
        .catch((error) => {});
    } else {
      setStates([]);
    }
  };


  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Addresses
          </div>
        </div>
      </div>
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <Navigation />
                <div className="col-md-9">
                  <div className="tab-content account dashboard-content">
                    <div
                      className="tab-pane fade active show"
                      id="address"
                      role="tabpanel"
                      aria-labelledby="address-tab"
                    >
                      <Link
                        to={
                          "/my-account/add-address/billing?add=" +
                          JSON.stringify(obj)
                        }
                        className="pb-3 p-2"
                        style={{ textAlign: "end" }}
                      >
                        {/* <h6 className="text-brand">Add Address?</h6> */}
                      </Link>
                      {!isLoading ? (
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="card mb-3 mb-lg-0"
                              style={{ minHeight: "200px" }}
                            >
                              <div className="card-header">
                                <h3 className="mb-0">Billing Address</h3>
                              </div>
                              <div className="card-body">
                                <address>
                                  {Object.keys(billingAdd).length > 0 ? (
                                    <>
                                      {billingAdd.address} <br />
                                      {billingAdd.city} <br />
                                      {billingAdd.state_name} <br />
                                      {billingAdd.pincode} <br />
                                    </>
                                  ) : (
                                    "No Address Available"
                                  )}
                                </address>

                                {/* <Link
                                to={
                                  "/my-account/edit-address/billing?add=" +
                                  JSON.stringify(billingAdd)
                                }
                                className="btn-small"
                              >
                                Edit
                              </Link> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="mb-0">Shipping Address</h3>
                              </div>
                              <div className="card-body">
                                <address>
                                  {Object.keys(shippingAdd).length > 0 ? (
                                    <>
                                      {shippingAdd?.address} <br />
                                      {shippingAdd?.city} <br />
                                      {shippingAdd?.state_name} <br />
                                      {shippingAdd?.pincode} <br />{" "}
                                    </>
                                  ) : (
                                    "No Address Available"
                                  )}
                                </address>
                                {/* <Link
                                to={
                                  "/my-account/edit-address/shipping?add=" +
                                  JSON.stringify(shippingAdd)
                                }
                                className="btn-small"
                              >
                                Edit
                              </Link> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Address;
