import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAccount, verifyLinkAPI } from "../../services/AuthService";
import { object, ref, string } from "yup";
import { useFormik } from "formik";

const CreateAccount = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [passwordType, setpasswordType] = useState(false);
  const [confirmPasswordType, setconfirmPasswordType] = useState("");
  const [invalidToggle, setinvalidToggle] = useState(false);
  const [id, setid] = useState("");

  const [state, setState] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyLink();
  }, []);

  const verifyLink = async () => {
    await verifyLinkAPI(params?.token)
      .then((response) => {
        setid(response?.data?.data?.id);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setinvalidToggle(true);
        if (error?.response?.status === 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status === 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  // const handleInputChange = (e) => {
  //   let { name, value } = e.target;
  //   setState({ ...state, [name]: value });
  // };
  const submitClicked = async (data) => {
    // e.preventDefault();
    // if (validateInput()) {
      await createAccount({ ...data, id, email_token: params?.token })
        .then((response) => {
          toast("Created Account Successfully", { type: "success" });
          navigate("/");
        })
        .catch((error) => {
          if (error?.response?.status === 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status === 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    // }
  };

  // const validateInput = () => {
  //   const usernameRegex = /^[a-zA-Z0-9]*$/;
  //   const passwordRegex =
  //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  //   if (state.username == "") {
  //     toast("Please enter username.", {
  //       type: "error",
  //     });
  //     return false;
  //   } else if (!usernameRegex.test(state.username)) {
  //     toast(
  //       "Username should not be contain any special character or any space.",
  //       {
  //         type: "error",
  //       }
  //     );
  //     return false;
  //   } else if (
  //     state?.username?.trim()?.length < 6 ||
  //     state?.username?.trim()?.length > 12
  //   ) {
  //     toast("Username Must be between 6 to 12 characters long.", {
  //       type: "error",
  //     });
  //     return false;
  //   }

  //   if (state.password === "") {
  //     toast("Please enter password.", {
  //       type: "error",
  //     });
  //     return false;
  //   }
  //   if (!passwordRegex.test(state.password)) {
  //     toast(
  //       "Password must contain at least one lowercase letter or one uppercase letter, one digit, one special character, and be at least 8 characters long.",
  //       {
  //         type: "error",
  //       }
  //     );
  //     return false;
  //   }

  //   if (state.confirmPassword === "") {
  //     toast("Please enter confirm password.", {
  //       type: "error",
  //     });
  //     return false;
  //   }

  //   if (state.confirmPassword !== state.password) {
  //     toast("Password and Confirm Password should be the same.", {
  //       type: "error",
  //     });
  //     return false;
  //   }

  //   return true;
  // };

    const AdminSchema = object().shape({
      username: string()
        .required("Please enter username.")
        .trim("Please enter username.")
        .matches(
          /^[a-zA-Z0-9]*$/,
          "Username should not be contain any Special character or any space. "
        )
        .min(6, "Username Must be between 6 to 12 characters long.")
        .max(12, "Username Must be between 6 to 12 characters long."),

      password: string()
        .required("Please enter password.")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password must contain at least one lowercase letter or one uppercase letter, one digit, one special character, and be at least 8 characters long."
        )
        .trim("Please enter password."),

      confirmPassword: string()
        .required("Please enter confirm password.")
        .trim("Please enter confirm password.")
        .oneOf(
          [ref("password")],
          "Password and confirm password does not match."
        ),
    });

      const {
        handleSubmit,
        handleBlur,
        handleChange,
        values,
        errors,
        touched,
      } = useFormik({
        validationSchema: AdminSchema,

        initialValues: {
          username: "",
          password: "",
          confirmPassword: "",
        },
        onSubmit: async (values, { resetForm }) => {
          submitClicked(values);
        },

        onReset: () => {},
      });


  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a to="/">
              <i className="fi-rs-home mr-5" />
              Home
            </a>
            <span /> Create Account
          </div>
        </div>
      </div>
      <div className="page-content login-page pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <div className="row">
                <div className="col-lg-6 pr-30 ">
                  {/* <img
                                        className="border-radius-15"
                                        src="assets/imgs/page/login-1.png"
                                        alt=""
                                    /> */}
                  {!invalidToggle && (
                    // <video
                    //   width="100%"
                    //   loading="lazy"
                    //   muted="muted"
                    //   src="/images/login-illustartion.mp4"
                    //   type="video/mp4"
                    //   autoPlay="autoplay"
                    //   loop="loop"
                    // ></video>
                    <div style={{ marginTop: "84px" }}>
                      <h6 className="mb-5">Username must:</h6>
                      <ol className="list-insider">
                        <li>Be between 6 and 12 characters</li>
                        <li>
                          Should not be contain any Special character or any
                          space
                        </li>
                      </ol>
                      <h6 className="mb-5 mt-4">Password must:</h6>
                      <ol className="list-insider">
                        <li>Be between 8 and 64 characters</li>
                        <li>A lowercase or uppercase character</li>
                        <li>A number</li>
                        <li>A special character</li>
                      </ol>
                    </div>
                  )}
                </div>
                {!invalidToggle ? (
                  <div className="col-lg-6 col-md-8">
                    <div className="login_wrap widget-taber-content background-white">
                      <div className="padding_eight_all bg-white">
                        <div className="heading_s1">
                          <h1 className="mb-30">Create Account</h1>

                          {/* <p className="mb-30 login-hover">
                          Don't have an account?{" "}
                          <Link to="/register">Create here</Link>
                        </p> */}
                        </div>
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <input
                              type="text"
                              required=""
                              name="username"
                              placeholder="Username *"
                              value={values?.username}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{
                                borderColor:
                                  touched?.username && errors?.username
                                    ? "red"
                                    : `var(--dark-container)`,
                              }}
                            />
                            {touched?.username && errors?.username && (
                              <span className="error-text">
                                {errors.username}
                              </span>
                            )}
                          </div>
                          <div className="form-group">
                            <input
                              required=""
                              type={passwordType ? "text" : "password"}
                              autoComplete="true"
                              placeholder="Your password *"
                              name="password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.password}
                              style={{
                                borderColor:
                                  touched?.password && errors?.password
                                    ? "red"
                                    : `var(--dark-container)`,
                              }}
                            />
                            <img
                              onClick={() => setpasswordType(!passwordType)}
                              src={
                                "/images/theme/icons/" +
                                (passwordType
                                  ? "icon-eye.svg"
                                  : "icon-eye-off.svg")
                              }
                              className="show-password"
                            />
                            {touched?.password && errors?.password && (
                              <span className="error-text">
                                {errors.password}
                              </span>
                            )}
                          </div>
                          <div className="form-group">
                            <input
                              required=""
                              type={passwordType ? "text" : "password"}
                              name="confirmPassword"
                              placeholder="Confirm Password *"
                              autoComplete="true"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.confirmPassword}
                              style={{
                                borderColor:
                                  touched?.confirmPassword &&
                                  errors?.confirmPassword
                                    ? "red"
                                    : `var(--dark-container)`,
                              }}
                            />
                            <img
                              onClick={() => setpasswordType(!passwordType)}
                              src={
                                "/images/theme/icons/" +
                                (passwordType
                                  ? "icon-eye.svg"
                                  : "icon-eye-off.svg")
                              }
                              className="show-password"
                            />
                            {touched?.confirmPassword &&
                              errors?.confirmPassword && (
                                <span className="error-text">
                                  {errors.confirmPassword}
                                </span>
                              )}
                          </div>

                          <div className="login_footer form-group mb-20">
                            {/* <div className="chek-form">
                                                        <div className="custome-checkbox">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="checkbox"
                                                                id="exampleCheckbox1"
                                                                defaultValue=""
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="exampleCheckbox1"
                                                            >
                                                                <span>Remember me</span>
                                                            </label>
                                                        </div>
                                                    </div> */}
                            {/* <Link
                            className="text-muted"
                            to="/my-account/lost-password"
                          >
                            Forgot password?
                          </Link> */}
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-heading btn-block hover-up"
                              name="login"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Create
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <h1 className="text-center">This link has been expired</h1>
                )}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateAccount;
