import React, { useContext, useEffect } from "react";
import Loader from "../Loader";
import { logout } from "../../services/AuthService";
import { AppContext } from "../../utils/AppProvider";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { isLogin } from "../../utils/constant";

const Redirect = () => {
  const { setIsAuthenticated } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params?.token && params?.id) {
      handleLogin();
    } else {
      navigate("/");
    }
  }, []);

  const handleLogin = () => {
    localStorage.removeItem("is_authenticated");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("opening_balance");
    localStorage.removeItem("adjustment_balance");

    setTimeout(() => {
       localStorage.setItem("is_authenticated", 1);
       localStorage.setItem("token", params?.token);

       localStorage.setItem("userId", params?.id);

       setIsAuthenticated(1);
       toast("Customer Login Successfully", { type: "success" });

       navigate("/");
    }, 1500);
   
   
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "400px",
      }}
    >
      <Loader />
    </div>
  );
};

export default Redirect;
