import React, { useState } from "react";

const ImagePopUp = ({
  src,
  onClick,
  onClickDialog,
  popupRef,
  closeClicked,
}) => {
  function ImageMagnifier({
    src,
    width,
    ref,
    height,
    magnifierHeight = 250,
    magnifieWidth = 250,
    zoomLevel = 1.5,
    disableZoom,
  }) {
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    return (
      <div
        style={{
          position: "relative",
          height: height,
          width: width,
        }}
        id="no_right_click"
      >
        <img
          src={src}
          style={{ height: height, width: width }}
          onMouseEnter={(e) => {
            if (!disableZoom) {
              // update image size and turn-on magnifier
              const elem = e.currentTarget;
              const { width, height } = elem.getBoundingClientRect();
              setSize([width, height]);
              setShowMagnifier(true);
            }
          }}
          onMouseMove={(e) => {
            if (!disableZoom) {
              // update cursor position
              const elem = e.currentTarget;
              const { top, left } = elem.getBoundingClientRect();

              // calculate cursor position on the image
              const x = e.pageX - left - window.pageXOffset;
              const y = e.pageY - top - window.pageYOffset;
              setXY([x, y]);
            }
          }}
          onMouseLeave={() => {
            // close magnifier

            setShowMagnifier(false);
          }}
          alt={"img"}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "#e57d26",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "24px",
            zIndex: 1000,
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={closeClicked}
        >
          X
        </button>

        <div
          style={{
            display: showMagnifier ? "" : "none",
            position: "absolute",

            // prevent maginier blocks the mousemove event of img
            pointerEvents: "none",
            // set size of magnifier
            height: `${magnifierHeight}px`,
            width: `${magnifieWidth}px`,
            // move element center to cursor pos
            top: `${y - magnifierHeight / 2}px`,
            left: `${x - magnifieWidth / 2}px`,
            opacity: "1", // reduce opacity so you can verify position
            border: "1px solid lightgray",
            backgroundColor: "white",
            backgroundImage: `url('${src}')`,
            backgroundRepeat: "no-repeat",

            //calculate zoomed image size
            backgroundSize: `${imgWidth * zoomLevel}px ${
              imgHeight * zoomLevel
            }px`,

            //calculete position of zoomed image.
            backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
            backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
          }}
        ></div>
      </div>
    );
  }
  return (
    <dialog
      ref={popupRef}
      className="dialog"
      style={{ position: "absolute", zIndex: 100, overflow: "hidden" }}
      open
      onClick={onClickDialog}
    >
      {/* <img
        style={{ width: "300px" }}
        src={src}
        onClick={onClick}
        alt="noImage"
      /> */}
      <ImageMagnifier
        width={"400px"}
        height={"400px"}
        src={src}
        // disableZoom={ true}
      />
    </dialog>
  );
};

export default ImagePopUp;
