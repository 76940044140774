import { useCallback, useContext } from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addItemToCart, addItemToWishlist, retriveSearchItems } from "../../services/ItemService";
import { AppContext } from "../../utils/AppProvider";
import {
  convertToSlug,
  decodeHTMLEntities,
  encodeHTMLEntities,
  isLogin,
} from "../../utils/constant";
import { getCartItemQty } from "../../services/CartService";

import { computeHeadingLevel } from "@testing-library/react";
import Loader from "../Loader";
import _ from "lodash";
import Blink from "react-blink-text";
import { userId } from "../../layout/Index";

let timeout;
const Search = () => {
  const [isHover, setisHover] = useState(false);
  const params = useParams();

  const [page, setPage] = useState(params?.page);
  const [perPage, setPerPage] = useState(50);
  const [hoverIndex, sethoverIndex] = useState();
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("Select Filter");
  // const [wishlistItems, setwishlistItems] = useState([]);
  const { search, setCartQty, wishlist_qty, setWishlistQty, cart_qty } =
    useContext(AppContext);
  const [showSortByItemCount, setShowSortByItemCount] = useState(false);
  const [showSortByList, setShowSortByList] = useState(false);
  const [links, setLinks] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const navigate = useNavigate();

  const noPhotoFound =
    process.env.REACT_APP_BASE_URL + "/storage/products/no-image-product.png";

   

  useEffect(() => {
    // setPage(1);
    setPerPage(50);
    setFilter("");
    retriveItems();
  }, [search]);

  useEffect(() => {
    if (params?.page !== page) {
      navigate(`/products/${page}`, { replace: true });
      retriveItems();
    }
  }, [page]);

  useEffect(() => {
    retriveItems();
    setFilter("");
  }, [perPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // asceding Order
    if (filter === "Price: Low to High") {
      const sorted_items = [...items].sort(
        (a, b) => a.sales_price - b.sales_price
      );
      setItems(sorted_items);
    }

    // Desceding Order
    if (filter == "Price: High to Low") {
      const sorted_items = [...items].sort(
        (a, b) => b.sales_price - a.sales_price
      );
      setItems(sorted_items);
    }
  }, [filter]);

  const setPerPageValue = (e, count) => {
    e.preventDefault();
    setPerPage(count);
    setShowSortByItemCount(false);
  };
  const setFilterValue = (e, value) => {
    e.preventDefault();
    setFilter(value);
    setShowSortByList(false);
  };

 

  const manipuateItemData = (itemDetail) => {
    let mainArray = [];
    for (let a of itemDetail) {
      let obj = a;
      if (a?.cart === null) {
        obj = {
          ...a,
          cart: { item_id: a?.id, quantity: 0 },
        };
      }

      mainArray.push({ ...obj, isItem: true });
    }

    return mainArray;
  };

  const retriveItems = () => {
    setisLoading(true);

    clearTimeout(timeout);
    timeout = setTimeout(function () {
      retriveSearchItems(search, page, perPage)
        .then((response) => {
          if (response?.data) {
            let data = response?.data?.data?.data;

            let itemData = manipuateItemData(data?.itemDetail?.data);
            //   let itemData=  data?.itemDetail?.data?.map((item?)=>{
            //     if(item?.cart === null){
            //         let obj ={
            //         'item_id':item?.id,
            //         'quantity':0
            //         }
            //         item?.cart = obj;
            //     }
            //     });
            setItems([...data?.groupDetail?.data, ...itemData]);
            setLinks(response.data?.data?.data?.groupDetail?.links);

            setisLoading(false);
            clearTimeout(timeout);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 300);
  };
  // const toggleWishlist = async (item, e) => {
  //   e.preventDefault();
  //   let items = await localStorage.getItem("wishlist_items");
  //   let set = 0;
  //   let date = new Date();
  //   date =
  //     date.toLocaleString("default", { month: "long" }) +
  //     " " +
  //     date.getDay() +
  //     ", " +
  //     date.getFullYear();

  //   if (items != null) {
  //     items = JSON.parse(items);
  //     let ids = items.map((item) => item["id"]);

  //     if (ids.includes(item?.id)) {
  //       let wishlist_items = items.filter((_item) => _item.id != item?.id);
  //       let wishlist_item_ids = wishlist_items.map((_item) => {
  //         return _item.id;
  //       });
  //       set = -1;
  //       setwishlistItems(wishlist_item_ids);
  //       localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
  //       toast("Item Removed from Favourites", { type: "success" });
  //     } else {
  //       item["wishlist_added_date"] = date;
  //       items.push(item);
  //       set = 1;
  //       setwishlistItems([...wishlistItems, item?.id]);
  //       localStorage.setItem("wishlist_items", JSON.stringify(items));
  //       toast("Item added into Favourites", { type: "success" });
  //     }
  //   } else {
  //     let temp = [];
  //     item["wishlist_added_date"] = date;
  //     temp.push(item);
  //     set = 1;
  //     setwishlistItems([item?.id]);
  //     localStorage.setItem("wishlist_items", JSON.stringify(temp));

  //     toast("Item added into Favourites", { type: "success" });
  //   }
  //   setWishlistQty(wishlist_qty + set);
  // };

   const wishlistToggle = async (id, e, isGroupToggle) => {
     e?.preventDefault();
     let formdata = new FormData();
     formdata.append("item_id", id);
     formdata.append("is_group", isGroupToggle);

     await addItemToWishlist(formdata)
       .then((response) => {
         toast(response?.data?.message, { type: "success" });
         let data = [];
         for (let a of items) {
           let value = a;
           if (a?.id === id) {
             if (a?.wishlist) {
               setWishlistQty(wishlist_qty - 1);
             } else {
               setWishlistQty(wishlist_qty + 1);
             }
             value = { ...a, wishlist: a?.wishlist ? 0 : 1 };
           }
           data?.push(value);
         }
         setItems(data);
       })
       .catch((error) => {
         if (error?.response?.status == 422)
           toast(error.response.data.error, { type: "error" });
         else if (error?.response?.status == 500)
           toast(error.response.data.message, { type: "error" });
         else toast("Something went wrong", { type: "error" });
       });
   };

  const handleAddToCart = (item, e) => {
    e.preventDefault();
    if (isLogin()) {
      let item_id = item?.id;
      let quantity = item?.cart.quantity;
      if (quantity <= 0) {
        // retriveItems();
        toast("Please Enter the quantity", { type: "error" });
        return;
      }
      addItemToCart(item_id, parseInt(quantity))
        .then((response) => {
          if (response.data.message == "no_stock") {
            toast("Out of stock!.", { type: "error" });
          } else {
            CountCartQty();
            toast("Item Added To Cart Successfully.", { type: "success" });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          if (error?.response?.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      toast("Please login to add item? to cart", { type: "error" });
    }
  };

  const handleCartQuantity = (index, value) => {
    let flag = false;
    if (!isNaN(value) && value >= 0) {
      if (items[index].stock >= value) {
        let _product = items;
        let _item = items[index].cart;
        _item["quantity"] = value;
        _product[index]["cart"] = _item;
        setItems([..._product]);
        flag = true;
      } else if (items[index].stock <= 0) {
        toast("Out Of Stock", { type: "error" });
      } else {
        toast(
          `Please Enter proper quantity,there are only ${items[index]?.stock} stock left.`,
          { type: "error" }
        );
      }
    } else {
      retriveItems();
      flag = true;
      toast("Please Enter the quantity", { type: "error" });
    }
    if (!flag) {
      let _product = items;
      let _item = items[index].cart;
      _item["quantity"] = 0;
      _product[index]["cart"] = _item;
      setItems([..._product]);
    }
  };

  const CountCartQty = () => {
    getCartItemQty()
      .then((response) => {
        setCartQty(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
        if (error?.response?.status == 422)
          toast(error.response.data.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <main className="main" style={{ transform: "none" }}>
      <div className="container mb-30" style={{ transform: "none" }}>
        <div className="col-lg-12">
          <div className="shop-product-fillter">
            <div className="totall-product">
              <p>
                We found <strong className="text-brand">{items.length}</strong>{" "}
                items for you!
              </p>
            </div>
            <div className="sort-by-product-area">
              <div className="sort-by-cover mr-10">
                <div
                  className="sort-by-product-wrap"
                  onClick={() => setShowSortByItemCount(!showSortByItemCount)}
                >
                  <div className="sort-by">
                    <span>
                      <i className="fi-rs-apps" />
                      Show:
                    </span>
                  </div>
                  <div className="sort-by-dropdown-wrap">
                    <span>
                      {" "}
                      {perPage} <i className="fi-rs-angle-small-down" />
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "sort-by-dropdown" + (showSortByItemCount ? " show" : "")
                  }
                >
                  <ul>
                    <li>
                      <a
                        className={perPage == 10 ? "active" : ""}
                        onClick={(e) => setPerPageValue(e, 10)}
                        href=""
                      >
                        10
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className={perPage == 20 ? "active" : ""}
                        onClick={(e) => setPerPageValue(e, 20)}
                      >
                        20
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className={perPage == 30 ? "active" : ""}
                        onClick={(e) => setPerPageValue(e, 30)}
                      >
                        30
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className={perPage == 40 ? "active" : ""}
                        onClick={(e) => setPerPageValue(e, 40)}
                      >
                        40
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className={perPage == 50 ? "active" : ""}
                        onClick={(e) => setPerPageValue(e, 50)}
                      >
                        50
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="sort-by-cover mr-25">
                <div
                  className="sort-by-product-wrap"
                  onClick={() => setShowSortByList(!showSortByList)}
                >
                  <div className="sort-by">
                    <span>
                      <i className="fi-rs-apps-sort" />
                      Sort by:
                    </span>
                  </div>
                  <div className="sort-by-dropdown-wrap">
                    <span>
                      {" "}
                      {filter} <i className="fi-rs-angle-small-down" />
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "sort-price-dropdown sort-by-dropdown" +
                    (showSortByList ? " show" : "")
                  }
                >
                  <ul>
                    <li>
                      <a
                        href=""
                        className={filter === "Select Filter" ? "active" : ""}
                        onClick={(e) => setFilterValue(e, "Select Filter")}
                      >
                        Select Filter
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className={
                          filter === "Price: Low to High" ? "active" : ""
                        }
                        onClick={(e) => setFilterValue(e, "Price: Low to High")}
                      >
                        Price: Low to High
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className={
                          filter === "Price: High to Low" ? "active" : ""
                        }
                        onClick={(e) => setFilterValue(e, "Price: High to Low")}
                      >
                        Price: High to Low
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className={filter == "Release Date" ? "active" : ""}
                        onClick={(e) => setFilterValue(e, "Release Date")}
                      >
                        Release Date
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isLoading ? (
          <div className="row product-grid-4  m-xl-5 m-lg-3 m-sm-0">
            {items?.map((item, index) => {
              let imagePath;
              if (item?.isItem) {
                imagePath =
                  process.env.REACT_APP_BASE_URL +
                  "/storage/products/" +
                  item?.image;
              } else {
                if (item?.groups_image) {
                  imagePath =
                    process.env.REACT_APP_BASE_URL +
                    "/storage/group_images/" +
                    item?.groups_image;
                } else if (item?.new_groups_image) {
                  imagePath =
                    process.env.REACT_APP_BASE_URL +
                    "/storage/products/" +
                    item?.new_groups_image;
                }
              }

              let ele = document?.getElementById(`no_right_click${index}`);
              ele?.addEventListener("contextmenu", (ev) => {
                ev?.preventDefault(); // this will prevent browser default behavior
              });

              let isItem = item?.isItem ? true : false;

              return (
                <div
                  key={index}
                  className="col-lg-1-4 col-md-3 col-12 col-sm-5 inner-product-grid"
                >
                  <div
                    className={`product-cart-wrap`}
                    style={{
                      borderColor: "var(--primary-color)",
                      minHeight: item?.isItem ? "400px" : "400px",
                      // pointerEvents: "none",
                    }}
                    //  ${!isHover?"mb-30":"mb-170"}

                    // id="hover-area"
                    // onMouseOver={()=>{console.log('called')}}
                    // onMouseMove={()=>{setisHover(true)}}
                    // onMouseEnter={()=>{setisHover(true)}}
                    //     setTimeout(() => {
                    //     setisHover(true)
                    //    }, 900)}}

                    onMouseLeave={() => {
                      setTimeout(() => {
                        setisHover(false);
                      }, 500);
                    }}
                  >
                    <div
                      className="product-img-action-wrap"
                      id={`no_right_click${index}`}
                    >
                      <div className="product-img product-img-zoom">
                        {item?.isItem ? (
                          <Link
                            to={
                              "/product/" +
                              item?.id +
                              "/" +
                              convertToSlug(item?.item_name) +
                              "/" +
                              isItem
                            }
                            state={{ isItem: item?.isItem }}
                          >
                            <img
                              className="default-img"
                              style={{
                                minHeight: "250px",
                                maxHeight: "250px",
                              }}
                              src={
                                !_.isEmpty(imagePath) ? imagePath : noPhotoFound
                              }
                              alt=""
                            />
                          </Link>
                        ) : (
                          <Link
                            to={
                              "/product/" +
                              item?.id +
                              "/" +
                              convertToSlug(item?.title) +
                              "/" +
                              isItem
                            }
                            state={{ isItem: item?.isItem }}
                          >
                            <img
                              className="default-img"
                              style={{ minHeight: "250px", maxHeight: "250px" }}
                              src={
                                !_.isEmpty(imagePath) ? imagePath : noPhotoFound
                              }
                              alt="group_image"
                            />
                          </Link>
                        )}
                      </div>
                     <div className="product-action-1">
                        <a
                          aria-label="Add To Favourites"
                          className="action-btn"
                          href=""
                          onClick={(e) => {
                            // toggleWishlist(item, e);
                            wishlistToggle(item?.id, e, !item?.isItem);
                          }}
                        >
                          {item?.wishlist ? (
                            <i className="wisilist-fill">&#10084;</i>
                          ) : (
                            <i className="fi-rs-heart" />
                          )}
                        </a>
                      </div>
                      <div className="product-badges product-badges-position product-badges-mrg">
                        <span className="hot">Hot</span>
                      </div>
                    </div>
                    <div
                      className="product-content-wrap"
                      style={{ height: "80px" }}
                    >
                      <div className="product-category">
                        {item?.isItem && (
                          <Link
                            to={
                              "/product-category/" +
                              item?.category?.slug +
                              "/1" 
                              //+ "/" +
                              // item?.isItem
                            }
                          >
                            {decodeHTMLEntities(item?.category.category_name)}
                          </Link>
                        )}
                      </div>
                      <h2>
                        {!item?.isItem ? (
                          <Link
                            to={
                              "/product/" +
                              item?.id +
                              "/" +
                              convertToSlug(item?.title) +
                              "/" +
                              isItem
                            }
                            className="ellipsis"
                            style={{
                              padding: "0px",
                              // minHeight: "60px",
                              // minWidth: "265px",
                            }}
                            state={{ isItem: item?.isItem }}
                          >
                            {/* <textarea className="bg-transparent border-none" style={{border:'none',color:'white'}}  rows={1} > */}

                            {decodeHTMLEntities(item?.title)}
                            {/* </textarea> */}
                          </Link>
                        ) : (
                          <Link
                            to={
                              "/product/" +
                              item?.id +
                              "/" +
                              convertToSlug(item?.item_name) +
                              "/" +
                              isItem
                            }
                            className="ellipsis"
                            style={{
                              padding: "0px",
                              // minHeight: "60px",
                              // minWidth: "265px",
                            }}
                            state={{ isItem: item?.isItem }}
                          >
                            {decodeHTMLEntities(item?.item_name)}
                          </Link>
                        )}
                      </h2>
                      {items[index]?.stock <= 0 && (
                        <div
                          style={{
                            textAlign: "end",
                            marginRight: "30px",
                          }}
                        >
                          <Blink color="red" text="Out Of Stock!" fontSize="50">
                            Out of stock!
                          </Blink>
                        </div>
                      )}
                    </div>
                    <div
                      className="product-content-wrap "
                      style={{ paddingTop: item?.isItem ? "0px" : "40px" }}
                      // style={{marginTop:'40px'}}
                    >
                      <div className="product-card-bottom">
                        {item?.isItem && (
                          <div className="row">
                            <div className="product-price">
                              {/* {
                                                        (item?.discount && item?.discount > 0) ?
                                                            <>
                                                                <span>${((item?.discount_type == 'Percentage') ? item?.sales_price - (item?.sales_price * item?.discount / 100) : (item?.discount_type == 'Fixed') ? item?.sales_price - item?.discount : item?.sales_price).toFixed(2)} </span>
                                                                <span className="old-price">${item?.sales_price.toFixed(2)}</span>
                                                            </>
                                                            : */}
                              <span>${item?.sales_price.toFixed(2)} </span>
                              {/* } */}
                            </div>
                            <span className="user-id-font">
                              <span> {userId}</span>
                            </span>
                          </div>
                        )}
                        {item?.isItem && (
                          <div className="detail-qty group-item?-qty border radius">
                            <a
                              onClick={(e) => {
                                if (items[index]?.stock > 0) {
                                  handleCartQuantity(
                                    index,
                                    Number(item?.cart?.quantity - 1)
                                  );
                                }
                              }}
                              className="qty-down"
                            >
                              <i className="fi-rs-angle-small-down" />
                            </a>
                            <input
                              type="text"
                              name="cart_qty"
                              className="qty-val"
                              min={0}
                              disabled={items[index]?.stock <= 0}
                              onChange={(e) => {
                                if (items[index]?.stock > 0) {
                                  handleCartQuantity(index, e.target.value);
                                }
                              }}
                              value={item?.cart?.quantity}
                            />
                            <a
                              onClick={(e) => {
                                if (items[index]?.stock > 0) {
                                  handleCartQuantity(
                                    index,
                                    Number(item?.cart?.quantity + 1)
                                  );
                                }
                              }}
                              className="qty-up"
                            >
                              <i className="fi-rs-angle-small-up" />
                            </a>
                          </div>
                        )}
                        {/* <input type='number' min={0} name="cart_qty" className="form-control cart_input" value={item?.cart?.quantity} onChange={(e) => handleCartQuantity(index,e.target.value)}/> */}
                        {/*                       
                                        <div className="add-cart">
                                        <a className="add" href="" onClick={(e) => handleAddToCart(item?,e)}>
                                            <i className="fi-rs-shopping-cart mr-5" />
                                            Add{" "}
                                        </a>
                                        </div> */}
                      </div>
                      {item?.isItem ? (
                        <p
                          onClick={(e) => {
                            e?.preventDefault();
                            if (items[index]?.stock > 0) {
                              handleAddToCart(item, e);
                            }
                          }}
                          className="btn w-100 hover-up mt-10"
                          style={{
                            padding: "8px",
                            backgroundColor: items[index]?.stock <= 0 && "gray",
                          }}
                        >
                          {item?.isItem && (
                            <i className="fi-rs-shopping-cart mr-5" />
                          )}
                          {item?.isItem ? "Add to Cart" : "View More"}
                        </p>
                      ) : (
                        <Link
                          to={
                            "/product/" +
                            item?.id +
                            "/" +
                            convertToSlug(item?.title) +
                            "/" +
                            isItem
                          }
                          state={{ isItem: item?.isItem }}
                        >
                          <p
                            className="btn w-100 hover-up mt-10"
                            style={{ padding: "8px" }}
                          >
                            View More
                          </p>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Loader />
        )}
        {items?.length > 0 ? (
          <div className="pagination-area mt-20 mb-20 d-flex justify-content-center">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                {/* <li className="page-item?">
                            <a className={"page-link page-arrow" + (page == 1 ? ' link-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); setPage(page - 1) }}>
                            <i className="fi-rs-arrow-small-left" />
                            </a>
                        </li> */}
                {links?.map((link, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        "page-item?" + (link.active ? " page-link-active" : "")
                      }
                    >
                      <a
                        className={"page-link page-arrow page-link-active"}
                        style={{
                          backgroundColor: link.active ? "#E57D26" : "",
                        }}
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          if (link.url) {
                            setPage(link.url?.split("page=")[1]);
                          }
                        }}
                      >
                        {link?.label
                          .replace(/&laquo; Previous/g, "<<")
                          .replace(/Next &raquo;/g, ">>")}
                      </a>
                    </li>
                  );
                })}

                {/* <li className="page-item? active">
                            <a className="page-link page-number" href="#">
                            {page}
                            </a>
                        </li>

                        <li className="page-item?">
                            <a className={"page-link page-arrow" + (perPage != items.length ? ' link-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); setPage(page + 1) }}>
                            <i className="fi-rs-arrow-small-right" />
                            </a>
                        </li> */}
              </ul>
            </nav>
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default Search;
