import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveAccountDetails = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/account-details", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveAddreses = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/addresses", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const updateBillingAddress = async (data) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/customer/update-billing-address",
        { ...data },
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const updateShippingAddress = async (data) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/customer/update-shipping-address",
        { ...data },
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const saveAccountDetails = async (customer) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/customer/save-account-details", customer, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};
const getCustomerCategories = async () => {
  let token = await localStorage.getItem("agent-token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/poscustomercategory`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};
const getPosPaymentTypes = async () => {
  let token = await localStorage.getItem("agent-token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/pospaymenttype`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const getPosShipVias = async () => {
  let token = await localStorage.getItem("agent-token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/poshipvia`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const getCustomerShippAddresses = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/pos-shipping-addresses`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteCertificate = async (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/delete-customer-certificates`, { ...data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

function handleAuthFailure(error, reject) {
  if (
    error?.response.status === 401 &&
    error?.response.data.message === "Unauthenticated."
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("is_authenticated");

    if (window.location.pathname != "/login") {
      window.location.href = "/#/login";
    }
  } else {
    reject(error);
  }
}

export {
  retriveAddreses,
  updateBillingAddress,
  updateShippingAddress,
  retriveAccountDetails,
  saveAccountDetails,
  getCustomerCategories,
  getPosPaymentTypes,
  getPosShipVias,
  getCustomerShippAddresses,
  deleteCertificate,
};
