import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createOrder,
  retriveCartAndCustomer,
} from "../../services/OrderService";
import { getCustomerShippAddresses } from "../../services/CustomerService";
// import { getAllStates } from "../../services/StateService";
import { AppContext } from "../../utils/AppProvider";
import { convertToSlug, decodeHTMLEntities } from "../../utils/constant";
import { getPOSStates } from "../../services/StateService";
import { getPosPaymentTypes } from "../../services/CustomerService";
import { getCartItemQty } from "../../services/CartService";
import { userId } from "../../layout/Index";

const Checkout = () => {
  const [order, setOrder] = useState({
    billing_address: {},
    shipping_address: {},
    items: [],
    subtotal: 0,
    totalSalesPrice: 0,
    total: 0,
    tax: 0,
    payment_method: "",
    note: "",
    hasShippingAddress: false,
  });
  const [acceptTAC, setAcceptTAC] = useState(false);
  const [states, setStates] = useState([]);
  const [multiAddress, setMultiAddress] = useState();
  const [defaultShippAdd, setDefaultShippAdd] = useState();
  const navigate = useNavigate();
  const { setCartQty, cart_qty } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [isNotAvailable, setisNotAvailable] = useState(false);
  const [paymentType, setPaymentType] = useState([]);
  const [selectedPaymentType, setselectedPaymentType] = useState("");
  const [note, setnote] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const params = useParams();
  const stateId = params?.stateId;

  useEffect(() => {
    window.scrollTo(0, 0);
    getDetails();
    getPOSState();
    getCstomersShippingAddresses();
  }, []);

  useEffect(() => {
    if (isNotAvailable) {
      toast("To proceed to checkout,please delete the blur items.", {
        type: "error",
      });

      navigate("/cart");
    }
  }, [isNotAvailable]);

  // const getState = async () => {
  //     await getAllStates().then((response) => {
  //         setStates(response.data);
  //     }).catch((error) => {

  //     });
  // }
  const getPOSState = async () => {
    await getPOSStates()
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((error) => {});
  };

  const getCstomersShippingAddresses = async () => {
    await getCustomerShippAddresses()
      .then((response) => {
        setMultiAddress(response.data.data);
      })
      .catch((error) => {});
  };

  const getPosPaymentType = async (paymentId) => {
    await getPosPaymentTypes()
      .then((response) => {
        setPaymentType(response.data.data);
        for (let a of response.data.data) {
          if (a?.id === paymentId) {
            setselectedPaymentType(a?.payment_type);
          }
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  // const filterInActiveItemFromPOS = (obj) => {
  //   let mainArray = [];

  //   for (let a of obj?.cart_items) {
  //     if (a?.item?.detail?.status === 1) {
  //       mainArray.push(a);
  //     }
  //   }
  //   return { ...obj, cart_items :mainArray};
  // };

  const getDetails = async () => {
    await retriveCartAndCustomer(stateId)
      .then((response) => {
        if (response?.data?.data) {
          let data = response?.data?.data;

          for (let a of data?.cart_items) {
            if (!a?.item?.detail?.showOnWeb || !a?.item?.status) {
              setisNotAvailable(true);
            }
          }

          if (data.status !== "approved") {
            toast("Only approved customer can order");
            navigate("/cart");
          }

          let shipp_add = {
            name: data.name,
            company_name: data.company_name,
            address: data.address,
            city: data.city,
            state_id: data.state_id,
            pincode: data.pincode,
            mobile: formattedNumber(data.mobile),
            email: data.email,
          };
          let bill_add = {
            name: data.name,
            company_name: data.company_name,
            mobile: formattedNumber(data.mobile),
            email: data.email,
            address: data?.billing_address || data.address,
            city: data?.billing_city || data.city,
            state_id: data?.billing_state || data.state_id,
            pincode: data?.billing_zip || data.pincode,
          };

          let temp = data.addresses[0];

          setDefaultShippAdd(shipp_add);
          let items = [];
          let st = 0;
          let taxTotal = 0;
          let sales_price = 0;
          data?.cart_items?.map((i, index) => {
            if (i.item.stock > 0 && i.item.stock >= i.quantity) {
              sales_price += i.quantity * i.item?.detail?.sales_price;
              // st += i.quantity * i.item?.detail?.final_price;
              if (i.item?.detail?.taxValue?.tax_product_amount) {
                taxTotal +=
                  Number(i?.quantity) *
                  Number(i?.item?.detail?.taxValue?.tax_product_amount);
              }
              let sumOfTaxAndPrice = i?.item?.detail?.sales_price;
              if (i.item?.detail?.taxValue?.tax_product_amount) {
                sumOfTaxAndPrice =
                  Number(i?.item?.detail?.sales_price) +
                  Number(i?.item?.detail?.taxValue?.tax_product_amount);
              }
              let obj = {
                item_name: i.item.item_name,
                item_id: i.item_id,
                image: i.item.image,
                quantity: i.quantity,
                // item_price: i?.item?.detail?.sales_price,
                item_price: sumOfTaxAndPrice,
                // subtotal: i.quantity * i?.item?.detail?.sales_price,
                subtotal: i.quantity * sumOfTaxAndPrice,
              };
              items.push(obj);
            }
          });

          if (items.length <= 0) {
            navigate("/cart");
          }

          setOrder({
            ...order,
            ["billing_address"]: bill_add,
            ["shipping_address"]: shipp_add,
            ["items"]: items,
            ["subtotal"]: sales_price + taxTotal,
            ["total"]: sales_price + taxTotal,
            ["totalSalesPrice"]: sales_price,
            ["tax"]: taxTotal,
            ["payment_method"]: data?.payment_type,
          });
          getPosPaymentType(data?.payment_type);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const handleBillingAddChange = (e) => {
    let { name, value } = e.target;
    let bill_add = { ...order.billing_address };
    if (name == "mobile") {
      if (value != null) {
        var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        value = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      }
    }
    bill_add[name] = value;
    // setOrder({ ...order, ["billing_address"]: bill_add });
  };

  const handleShippingAddChange = (e) => {
    let { name, value } = e.target;
    let shipp_add = { ...order.shipping_address };
    if (name == "mobile") {
      if (value != null) {
        var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        value = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      }
    }
    shipp_add[name] = value;

    // setOrder({ ...order, ["shipping_address"]: shipp_add });
  };

  const formattedNumber = (value) => {
    if (value != null) {
      var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    }
  };
  const UnformattedBillingNumber = (value) => {
    if (value != null) {
      let number = value.replace(/\D/g, "");
      let _order = order;
      let obj = order.billing_address;
      obj["mobile"] = number;
      _order["billing_address"] = obj;
      setOrder(_order);
    }
  };
  const UnformattedShippingNumber = (value) => {
    if (value != null) {
      let number = value.replace(/\D/g, "");
      let _order = order;
      let obj = order.shipping_address;
      obj["mobile"] = number;
      _order["shipping_address"] = obj;
      setOrder(_order);
    }
  };

  const handleOrderSubmit = async (e) => {
    e.preventDefault();

    if (validateInput() && !isLoading) {
      setisLoading(true);
      UnformattedBillingNumber(order?.billing_address?.mobile);
      if (order?.shipping_address?.mobile != "") {
        UnformattedShippingNumber(order?.shipping_address?.mobile);
      }
      setLoader(!loader);

      await createOrder({ ...order, special_note: note })
        .then((response) => {
          toast("Order Created Successfully.", { type: "success" });
          setLoader(false);
          setisLoading(false);
          CountCartQty();
          navigate("/my-account/orders");
        })
        .catch((error) => {
          setLoader(false);
          setisLoading(false);
          if (error?.response?.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };
  const CountCartQty = () => {
    getCartItemQty()
      .then((response) => {
        setCartQty(response.data.data);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error?.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };
  const changeShipingAddress = (id) => {
    if (id == 0) {
      if (defaultShippAdd == undefined) {
        let shipp_add = {
          selected_id: 0,
          customer_name: "",
          company_name: "",
          address: "",
          city: "",
          state_id: "",
          pincode: "",
          mobile: "",
        };
        // setOrder({
        //   ...order,
        //   ["shipping_address"]: shipp_add,
        // });
      } else {
        // setOrder({
        //   ...order,
        //   ["shipping_address"]: defaultShippAdd,
        // });
      }
    } else {
      multiAddress.map((address, index) => {
        if (address.id == id) {
          let shipp_add = {
            selected_id: address.id,
            customer_name: address.contact_name,
            company_name: address.company_name,
            address: address.address,
            city: address.city,
            state_id: address.state_id,
            pincode: address.postcode,
            mobile: formattedNumber(address.mobile),
          };
          // setOrder({
          //   ...order,
          //   ["shipping_address"]: shipp_add,
          // });
        }
      });
    }
  };
  const validateInput = () => {
    // Validate Billing Address
    if (order.billing_address.name == "") {
      toast("Please enter name in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.address == "") {
      toast("Please enter address in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.city == "") {
      toast("Please enter city in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.state_id == "") {
      toast("Please select state in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.pincode == "") {
      toast("Please enter Zipcode in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.phone == "") {
      toast("Please enter phone.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.email == "") {
      toast("Please enter email address.", {
        type: "error",
      });
      return false;
    }

    // Validate Shipping Address If Enabled
    if (order.hasShippingAddress) {
      if (order.shipping_address.customer_name == "") {
        toast("Please enter name in shipping address.", {
          type: "error",
        });
        return false;
      }
      if (order.shipping_address.address == "") {
        toast("Please enter address in shipping address.", {
          type: "error",
        });
        return false;
      }
      if (order.shipping_address.city == "") {
        toast("Please enter city in shipping address.", {
          type: "error",
        });
        return false;
      }
      if (order.shipping_address.state_id == "") {
        toast("Please select state in shipping address.", {
          type: "error",
        });
        return false;
      }
      if (order.shipping_address.pincode == "") {
        toast("Please enter Zipcode in shipping address.", {
          type: "error",
        });
        return false;
      }
      if (order.shipping_address.mobile == "") {
        toast("Please enter Primary Contact in shipping address.", {
          type: "error",
        });
        return false;
      }
    }

    if (order.payment_method == "") {
      toast("Please select payment type.", {
        type: "error",
      });
      return false;
    }
    if (!acceptTAC) {
      toast("Please accept Term and Condition.", {
        type: "error",
      });
      return false;
    }

    return true;
  };

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Checkout
          </div>
        </div>
      </div>
      <div className="container mb-80 mt-50">
        <div className="row">
          <div className="col-lg-8 mb-40">
            <h1 className="heading-2 mb-10">Checkout</h1>
            <div className="d-flex justify-content-between">
              <h6 className="text-body">
                There are <span className="text-brand">{cart_qty}</span>{" "}
                products in your cart
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="row">
              <h4>Shipping Details</h4>
              <span className="mb-30">
                (If you need to make any changes to your address, please contact
                Silverline Wholesale.)
              </span>
              <form method="post">
                <div className="row d-flex">
                  <div className="form-group col-lg-6">
                    <label className="">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      required=""
                      className="form-control"
                      name="Full name *"
                      disabled={true}
                      value={order.shipping_address?.name}
                      // onChange={(e) => handleDetailsChange(e)}
                      type="text"
                    />
                  </div>

                  <div className="form-group col-lg-6">
                    <label className="">
                      Company Name <span className="required">*</span>
                    </label>
                    <input
                      required=""
                      className="form-control"
                      name="company_name"
                      disabled={true}
                      // onChange={(e) => handleDetailsChange(e)}
                      value={order.shipping_address?.company_name}
                      type="text"
                    />
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="form-group col-lg-6">
                    <label>
                      Email <span className="required">*</span>
                    </label>
                    <input
                      required=""
                      type="text"
                      disabled={true}
                      name="email"
                      placeholder="Email address *"
                      value={order.shipping_address?.email}
                      onChange={(e) => handleBillingAddChange(e)}
                    />
                  </div>
                  <div className="form-group col-lg-6 ">
                    <label>
                      Primary Contact Number <span className="required">*</span>
                    </label>
                    <input
                      required=""
                      type="text"
                      disabled={true}
                      name="mobile"
                      placeholder="Primary Contact #"
                      value={order.shipping_address?.mobile}
                      onChange={(e) => handleBillingAddChange(e)}
                    />
                  </div>
                </div>
                <h6 className="mb-20 mt-10">Shipping Address</h6>
                <div className="row">
                  <div className="form-group col-lg-12">
                    <label>
                      Shipping Address <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      name="address"
                      required=""
                      disabled={true}
                      placeholder="Address *"
                      value={order.shipping_address?.address}
                      // onChange={(e) => handleBillingAddChange(e)}
                    />
                  </div>
                </div>
                <div className="row shipping_calculator">
                  <div className="form-group col-lg-6">
                    <label>
                      Shipping City <span className="required">*</span>
                    </label>
                    <input
                      required=""
                      type="text"
                      disabled={true}
                      name="city"
                      placeholder="City / Town *"
                      value={order.shipping_address?.city}
                      // onChange={(e) => handleBillingAddChange(e)}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label>
                      Shipping State <span className="required">*</span>
                    </label>
                    <div className="custom_select">
                      <select
                        className="form-control select-active"
                        data-select2-id={7}
                        tabIndex={-1}
                        disabled={true}
                        style={{ height: "64px" }}
                        aria-hidden="true"
                        name="state_id"
                        value={order.shipping_address?.state_id}
                        onChange={(e) => handleBillingAddChange(e)}
                      >
                        <option value="">Select an state</option>
                        {states.map((state, index) => {
                          return (
                            <option value={state.id}> {state.state} </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-6">
                  <label>
                    Shipping Zipcode <span className="required">*</span>
                  </label>
                  <input
                    required=""
                    type="text"
                    disabled={true}
                    name="pincode"
                    placeholder="ZIP *"
                    value={order.shipping_address?.pincode}
                    onChange={(e) => handleBillingAddChange(e)}
                  />
                </div>

                <h6 className="mb-20 mt-10">Billing Address</h6>

                <div className="row">
                  <div className="form-group col-lg-12">
                    <label>
                      Billing Address <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      name="address"
                      required=""
                      disabled={true}
                      placeholder="Billing Address *"
                      value={order.billing_address?.address}
                      // onChange={(e) => handleBillingAddChange(e)}
                    />
                  </div>
                </div>
                <div className="row shipping_calculator">
                  <div className="form-group col-lg-6">
                    <label>
                      Billing City <span className="required">*</span>
                    </label>
                    <input
                      required=""
                      type="text"
                      disabled={true}
                      name="city"
                      placeholder="Billing City / Town *"
                      value={order.billing_address?.city}
                      // onChange={(e) => handleBillingAddChange(e)}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label>
                      Billing State <span className="required">*</span>
                    </label>
                    <div className="custom_select">
                      <select
                        className="form-control select-active"
                        data-select2-id={7}
                        tabIndex={-1}
                        disabled={true}
                        style={{ height: "64px" }}
                        aria-hidden="true"
                        name="state_id"
                        value={order.billing_address?.state_id}
                        onChange={(e) => handleBillingAddChange(e)}
                      >
                        <option value="">Select an state</option>
                        {states.map((state, index) => {
                          return (
                            <option value={state.id}> {state.state} </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-6">
                  <label>
                    Billing Zipcode <span className="required">*</span>
                  </label>
                  <input
                    required=""
                    type="text"
                    disabled={true}
                    name="pincode"
                    placeholder="Billing ZIP *"
                    value={order.billing_address?.pincode}
                    // onChange={(e) => handleBillingAddChange(e)}
                  />
                </div>
                <div className="form-group col-lg-6">
                  <h6 className="mb-2">Special Note</h6>
                  <textarea
                    type="text"
                    rows={2}
                    placeholder="Special Note"
                    value={note}
                    maxLength={120}
                    style={{
                      borderColor: "var(--primary-color)",
                    }}
                    onChange={(e) => setnote(e?.target?.value)}
                  />
                </div>
                {/* <div className="form-group mb-30">
                  <textarea
                    rows={5}
                    placeholder="Additional information"
                    defaultValue={""}
                    name="note"
                    disabled={true}
                    value={order.note}
                    onChange={(e) => handleBillingAddChange(e)}
                  />
                </div> */}
                <Link
                  // to={
                  //   "/my-account/add-address/billing?add=" +
                  //   JSON.stringify({
                  //     address: "",
                  //     city: "",
                  //     state_id: "",
                  //     state_name: "",
                  //     pincode: "",
                  //     isAddAddress: true,
                  //   })
                  // }
                  className="pb-3"
                >
                  {/* <h6 className="text-brand">Add Address ?</h6> */}
                </Link>
                <div className="ship_detail">
                  <div className="form-group">
                    <div className="chek-form">
                      {/* <div className="custome-checkbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          disabled={true}
                          name="checkbox"
                          id="differentaddress"
                          checked={order.hasShippingAddress}
                          onChange={(e) => {
                            let { checked } = e.target;
                            setOrder({
                              ...order,
                              ["hasShippingAddress"]: checked,
                            });
                          }}
                        />
                        <label
                          className="form-check-label label_info"
                          data-bs-toggle="collapse"
                          data-target="#collapseAddress"
                          href="#collapseAddress"
                          disabled={true}
                          aria-controls="collapseAddress"
                          htmlFor="differentaddress"
                        >
                          
                          <span>Ship to a different address?</span>
                        </label>
                      </div> */}
                    </div>
                  </div>
                  <div
                    id="collapseAddress"
                    className={
                      "different_address collapse in" +
                      (order.hasShippingAddress ? " show" : "")
                    }
                  >
                    {multiAddress ? (
                      <div className="row mb-2" style={{ marginLeft: "15px" }}>
                        <div
                          className="row card col-lg-3 pt-2 mb-2"
                          style={{ marginRight: "20px" }}
                        >
                          <div className="form-check">
                            <input
                              style={{ cursor: "pointer" }}
                              type="radio"
                              id="radio_0"
                              className="form-check-input"
                              disabled={true}
                              name="ship_select"
                              onChange={() => changeShipingAddress(0)}
                              checked={
                                0 == order?.shipping_address?.selected_id
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              className="form-check-label"
                              htmlFor="radio_0"
                            >
                              Default Shipping Address
                            </label>
                          </div>
                        </div>
                        {multiAddress?.map((maddress, index) => {
                          return (
                            <div
                              className="row card col-lg-3 pt-2 mb-2"
                              key={index}
                              style={{ marginRight: "20px" }}
                            >
                              <div className="form-check">
                                <input
                                  style={{ cursor: "pointer" }}
                                  type="radio"
                                  id={"radio_" + ++index}
                                  className="form-check-input"
                                  disabled={true}
                                  name="ship_select"
                                  onChange={() =>
                                    changeShipingAddress(maddress.id)
                                  }
                                  checked={
                                    maddress.id ==
                                    order?.shipping_address?.selected_id
                                  }
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  className="form-check-label"
                                  htmlFor={"radio_" + index}
                                >
                                  {maddress.address} <br />
                                  {maddress.city}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <input
                          type="text"
                          required=""
                          disabled={true}
                          name="name"
                          placeholder="Full name *"
                          value={order.shipping_address?.customer_name}
                          onChange={(e) => handleShippingAddChange(e)}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          type="text"
                          required=""
                          disabled={true}
                          name="company_name"
                          placeholder="Company name"
                          value={order.shipping_address?.company_name}
                          onChange={(e) => handleShippingAddChange(e)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <input
                          type="text"
                          name="address"
                          required=""
                          disabled={true}
                          placeholder="Address *"
                          value={order.shipping_address?.address}
                          onChange={(e) => handleShippingAddChange(e)}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          type="text"
                          name="city"
                          required=""
                          disabled={true}
                          placeholder="City"
                          value={order.shipping_address?.city}
                          onChange={(e) => handleShippingAddChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <div className="custom_select">
                          <select
                            className="form-control select-active"
                            data-select2-id={7}
                            tabIndex={-1}
                            style={{ height: "64px" }}
                            aria-hidden="true"
                            disabled={true}
                            name="state_id"
                            value={order.shipping_address?.state_id}
                            onChange={(e) => handleShippingAddChange(e)}
                          >
                            <option value="">Select State</option>
                            {states.map((state, index) => {
                              return (
                                <option value={state.id}>
                                  {" "}
                                  {state.state}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          required=""
                          type="text"
                          name="pincode"
                          disabled={true}
                          placeholder="ZIP *"
                          value={order.shipping_address?.pincode}
                          onChange={(e) => handleShippingAddChange(e)}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          required=""
                          type="text"
                          disabled={true}
                          name="mobile"
                          placeholder="Primary Contact #"
                          value={order.shipping_address?.mobile}
                          onChange={(e) => handleShippingAddChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="border p-40 cart-totals ml-30 mb-50">
              <div className="d-flex align-items-end justify-content-between mb-30">
                <h4>Your Order</h4>
                <h6 className="text-muted">Subtotal</h6>
              </div>
              <div className="divider-2 mb-30" />
              <div
                className="table-responsive order_table checkout"
                style={{ height: "600px" }}
              >
                <table className="table no-border">
                  <tbody>
                    {order.items.map((i, index) => {
                      let ell = document?.getElementById(`disableIdd${index}`);
                      ell?.addEventListener("contextmenu", (ev) => {
                        ev?.preventDefault(); // this will prevent browser default behavior
                      });

                      return (
                        <tr>
                          <td
                            className="image product-thumbnail"
                            style={{ display: "flex" }}
                            id={`disableIdd${index}`}
                          >
                            <img
                              width={100}
                              height={100}
                              src={
                                i?.image
                                  ? process.env.REACT_APP_BASE_URL +
                                    "/storage/products/" +
                                    i.image
                                  : process.env.REACT_APP_BASE_URL +
                                    "/storage/products/no-image.png"
                              }
                              alt="#"
                            />
                          </td>
                          <td>
                            <h6 className="w-160 mb-5">
                              <Link
                                target="_blank"
                                to={
                                  "/product/" +
                                  i.item_id +
                                  "/" +
                                  convertToSlug(i.item_name) +
                                  "/" +
                                  true
                                }
                                className="text-heading"
                              >
                                {decodeHTMLEntities(i.item_name)}
                              </Link>
                            </h6>
                            <div className="product-rate-cover">
                              <div className="product-rate d-inline-block">
                                <div
                                  className="product-rating"
                                  style={{ width: "90%" }}
                                ></div>
                              </div>
                              <span className="font-small ml-5 text-muted">
                                {" "}
                                (4.0)
                              </span>
                              {/* <ul>
                                <li>
                                  Brand :{" "}
                                  <span className="text-brand mr-3">
                                    {" "}
                                    {i?.item?.product_code}
                                  </span>
                                </li>
                                <li>
                                  Product Code :{" "}
                                  <span className="text-brand mr-3">
                                    {" "}
                                    {i?.item?.product_code}
                                  </span>
                                </li>

                                <li>
                                  Category :{" "}
                                  <span className="text-brand mr-3">
                                    {" "}
                                    {i?.item?.product_code}
                                  </span>
                                </li>
                                <li>
                                  Color :{" "}
                                  <span className="text-brand mr-3">
                                    {" "}
                                    {i?.item?.product_code}
                                  </span>
                                </li>
                                <li>
                                  Size :{" "}
                                  <span className="text-brand mr-3">
                                    {" "}
                                    {i?.item?.product_code}
                                  </span>
                                </li>
                                <li>
                                  Flavour :{" "}
                                  <span className="text-brand mr-3">
                                    {" "}
                                    {i?.item?.product_code}
                                  </span>
                                </li>
                                <li>
                                  Unit :{" "}
                                  <span className="text-brand mr-3">
                                    {" "}
                                    {i?.item?.product_code}
                                  </span>
                                </li>
                              </ul> */}
                            </div>
                          </td>
                          <td>
                            <h6 className="text-muted pl-20 pr-20">
                              ${i?.item_price?.toFixed(2)} x {i.quantity}
                            </h6>
                          </td>
                          <td>
                            <h5 className="text-brand">
                              ${i?.subtotal?.toFixed(2)}
                            </h5>
                            <span className="user-id-font">
                              <span> {userId}</span>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="divider-2 mb-3" />
              <div className="">
                <label className="">
                  <input
                    type="checkbox"
                    className=""
                    style={{
                      width: "17px",
                      height: "17px",
                      marginRight: "10px",
                    }}
                    name="terms"
                    id=""
                    checked={acceptTAC}
                    onChange={(e) => setAcceptTAC(e.target.checked)}
                  />
                  <span className="">
                    I have read and agree to the website{" "}
                    <Link to="/terms-and-conditions" target="_blank">
                      terms and conditions
                    </Link>
                  </span>
                  &nbsp;
                  <abbr className="required" title="required">
                    {" "}
                    *{" "}
                  </abbr>
                </label>
              </div>
              <div className="d-flex align-items-end justify-content-between">
                <h6>Sub Total</h6>
                <h6 className="text-muted">
                  {order?.totalSalesPrice?.toFixed(2)}
                </h6>
              </div>
              <div className="d-flex align-items-end justify-content-between">
                <h6>Tax</h6>
                <h6 className="text-muted">
                  {order?.tax ? order?.tax?.toFixed(2) : "-"}
                </h6>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-2">
                <h4>Grand Total</h4>
                <h4 className="text-muted">{order?.total?.toFixed(2)}</h4>
              </div>
            </div>

            <div className="payment ml-30">
              <h4 className="mb-30">Payment</h4>
              <p>
                {order.payment_method !== "" && paymentType.length > 0
                  ? selectedPaymentType
                  : "Your payment method is not defined. Please contact Administration"}
              </p>

              <div className="payment_option form-group col-md-6 col-10">
                {/* <div className="custome-radio">
                                    <input
                                        className="form-check-input"
                                        required=""
                                        type="radio"
                                        name="payment_option"
                                        id="exampleRadios3"
                                        defaultChecked=""
                                        disabled
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios3"
                                        data-bs-toggle="collapse"
                                        data-target="#bankTranfer"
                                        aria-controls="bankTranfer"
                                    >
                                        Direct Bank Transfer
                                    </label>
                                </div>
                                <div className="custome-radio">
                                    <input
                                        className="form-check-input"
                                        required=""
                                        type="radio"
                                        name="payment_option"
                                        id="exampleRadios4"
                                        checked
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios4"
                                        data-bs-toggle="collapse"
                                        data-target="#checkPayment"
                                        aria-controls="checkPayment"
                                    >
                                        Cash on delivery
                                    </label>
                                </div>
                                <div className="custome-radio">
                                    <input
                                        className="form-check-input"
                                        required=""
                                        type="radio"
                                        name="payment_option"
                                        id="exampleRadios5"
                                        defaultChecked=""
                                        disabled
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios5"
                                        data-bs-toggle="collapse"
                                        data-target="#paypal"
                                        aria-controls="paypal"
                                    >
                                        Online Getway
                                    </label>
                                </div> */}
              </div>
              <div className="payment-logo d-flex">
                <img
                  className="mr-15"
                  src="/images/theme/icons/payment-paypal.svg"
                  alt=""
                />
                <img
                  className="mr-15"
                  src="/images/theme/icons/payment-visa.svg"
                  alt=""
                />
                <img
                  className="mr-15"
                  src="/images/theme/icons/payment-master.svg"
                  alt=""
                />
                <img src="/images/theme/icons/payment-zapper.svg" alt="" />
              </div>
              {order.payment_method !== "" && (
                <a
                  disabled={isLoading}
                  onClick={(e) => handleOrderSubmit(e)}
                  className="btn btn-fill-out btn-block mt-30"
                >
                  {isLoading ? "Loading..." : "Place an Order"}
                  <i className="fi-rs-sign-out ml-15" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Checkout;
