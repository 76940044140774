import { useEffect } from "react";
import { useState } from "react";
import {
  addItemToCart,
  retriveFrequentlyOrderedItems,
  retriveHomePageItems,
  retrivePromotedItems,
  cartCount,
  addItemToWishlist,
  retriveDynamicImage,
  retriveDynamicSliderImage,
} from "../../services/ItemService";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  convertToSlug,
  decodeHTMLEntities,
  isLogin,
} from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";
import Slider from "./Slider";
import ShopByCategory from "./ShopByCategory";
import PromotedItems from "./PromotedItems";
import FrequentlyOrderedItems from "./FrequentlyOrderedItems";
import { getCartItemQty } from "../../services/CartService";
import Slider2 from "./Slider2";
import Loader from "../Loader";
import { userId } from "../../layout/Index";
import Blink from "react-blink-text";

const Home = () => {
  const [topProducts, setTopProducts] = useState([]);
  const [promotedItems, setPromotedItems] = useState([]);
  const [frequentlyOrderedItems, setFrequentlyOrderedItems] = useState([]);
  const [products, setProducts] = useState([]);
  const { setCartQty, wishlist_qty, setWishlistQty, is_authenticated } =
    useContext(AppContext);
  const [sellingProductIsLoading, setsellingProductIsLoading] = useState(true);
  const [topProdoctIsLoading, settopProdoctIsLoading] = useState(true);
  const [silderImages, setsilderImages] = useState([]);
  const [singleImages, setsingleImages] = useState({
    type1: {},
    type2: {},
    type3: {},
    type4: {},
    type5: {},
  });
  const [sliderIsloading, setsliderIsloading] = useState(true);
  const [singleImgIsloading, setsingleImgIsloading] = useState(true);
  let widthOfPage = window.innerWidth;
  const [isLoading1, setisLoading1] = useState(false);

  useEffect(() => {
    widthOfPage = window.innerWidth;
  }, [window.innerWidth]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getItems();
    getDynamicImage();
    getDynamicSliderImage();
    CountCartQty();
  }, []);

  useEffect(() => {
    getAllItem();
  }, [is_authenticated]);

  const getAllItem = () => {
    getItems();
    if (is_authenticated == 1) {
      getPromotedItems();
      getFrequentlyOrderedItems();
    } else {
      setPromotedItems([]);
      setFrequentlyOrderedItems([]);
    }
  };

  const getItems = async () => {
    await retriveHomePageItems()
      .then((response) => {
        if (response.data.data) {
          let data = response.data.data;
          data.map((item) => {
            if (item.cart == null) {
              let obj = {
                item_id: item.id,
                quantity: 0,
              };
              item.cart = obj;
            }
          });
          setsellingProductIsLoading(false);
          settopProdoctIsLoading(false);
          setTopProducts(data.filter((cat, index) => index <= 5));
          setProducts(data.filter((cat, index) => index > 5));
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getDynamicSliderImage = async () => {
    setisLoading1(true);
    await retriveDynamicSliderImage()
      .then((response) => {
        if (response.data.data) {
          setisLoading1(false);

          setsilderImages(response?.data?.data, "<<<");
          setsliderIsloading(false);
        }
      })
      .catch((error) => {
        setisLoading1(false);

        console.log("Error", error);
      });
  };

  const getDynamicImage = async () => {
    setisLoading1(true);

    await retriveDynamicImage()
      .then((response) => {
        setsingleImgIsloading(false);
        if (response.data.data) {
          setisLoading1(false);

          let type1 = {};
          let type2 = {};
          let type3 = {};
          let type4 = {};
          let type5 = {};
          for (let a of response?.data?.data) {
            if (a?.type === 1) {
              type1 = a;
            } else if (a?.type === 2) {
              type2 = a;
            } else if (a?.type === 3) {
              type3 = a;
            } else if (a?.type === 4) {
              type4 = a;
            } else if (a?.type === 5) {
              type5 = a;
            }
          }

          setsingleImages({
            type1,
            type2,
            type3,
            type4,
            type5,
          });
        }
      })
      .catch((error) => {
        setisLoading1(false);

        console.log("Error", error);
      });
  };

  const getPromotedItems = async () => {
    await retrivePromotedItems()
      .then((response) => {
        if (response.data.data) {
          let data = response.data.data;
          data.map((item) => {
            if (item.cart == null) {
              let obj = {
                item_id: item.id,
                quantity: 0,
              };
              item.cart = obj;
            }
          });
          setPromotedItems(data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getFrequentlyOrderedItems = async () => {
    await retriveFrequentlyOrderedItems()
      .then((response) => {
        if (response.data.data) {
          let data = response.data.data;
          data.map((item) => {
            if (item.cart == null) {
              let obj = {
                item_id: item.id,
                quantity: 0,
              };
              item.cart = obj;
            }
          });
          setFrequentlyOrderedItems(data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  // const toggleWishlist = async (item, e) => {
  //   e.preventDefault();
  //   let items = await localStorage.getItem("wishlist_items");
  //   let set = 0;
  //   let date = new Date();
  //   date =
  //     date.toLocaleString("default", { month: "long" }) +
  //     " " +
  //     date.getDay() +
  //     ", " +
  //     date.getFullYear();

  //   if (items != null) {
  //     items = JSON.parse(items);
  //     let ids = items.map((item) => item["id"]);

  //     if (ids.includes(item.id)) {
  //       let wishlist_items = items.filter((_item) => _item.id != item.id);
  //       let wishlist_item_ids = wishlist_items.map((_item) => {
  //         return _item.id;
  //       });
  //       set = -1;
  //       setwishlistItems(wishlist_item_ids);
  //       localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
  //       toast("Item Removed from Wishlist", { type: "success" });
  //     } else {
  //       item["wishlist_added_date"] = date;
  //       items.push(item);
  //       set = 1;
  //       setwishlistItems([...wishlistItems, item.id]);
  //       localStorage.setItem("wishlist_items", JSON.stringify(items));
  //       toast("Item added into Wishlist", { type: "success" });
  //     }
  //   } else {
  //     let temp = [];
  //     item["wishlist_added_date"] = date;
  //     temp.push(item);
  //     set = 1;
  //     setwishlistItems([item.id]);
  //     localStorage.setItem("wishlist_items", JSON.stringify(temp));

  //     toast("Item added into Wishlist", { type: "success" });
  //   }
  //   setWishlistQty(wishlist_qty + set);
  // };

  const wishlistToggle = async (item, e, isGroupToggle) => {
    e?.preventDefault();
    let formdata = new FormData();
    formdata.append("item_id", item);
    formdata.append("is_group", isGroupToggle);

    await addItemToWishlist(formdata)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });
        let data = [];
        for (let a of products) {
          let value = a;
          if (a?.id === item) {
            if (a?.wishlist) {
              setWishlistQty(wishlist_qty - 1);
            } else {
              setWishlistQty(wishlist_qty + 1);
            }
            value = { ...a, wishlist: a?.wishlist ? 0 : 1 };
          }
          data?.push(value);
        }
        setProducts(data);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const wishlistToggleforTopProducts = async (item, e, isGroupToggle) => {
    e?.preventDefault();
    let formdata = new FormData();
    formdata.append("item_id", item);
    formdata.append("is_group", isGroupToggle);

    await addItemToWishlist(formdata)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });
        let data = [];
        for (let a of topProducts) {
          let value = a;
          if (a?.id === item) {
            if (a?.wishlist) {
              setWishlistQty(wishlist_qty - 1);
            } else {
              setWishlistQty(wishlist_qty + 1);
            }
            value = { ...a, wishlist: a?.wishlist ? 0 : 1 };
          }
          data?.push(value);
        }
        setTopProducts(data);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const handleAddToCart = (item, e) => {
    e.preventDefault();
    if (isLogin()) {
      let item_id = item.id;
      let quantity = item.cart.quantity;
      if (quantity <= 0) {
        getAllItem();
        toast("Please Enter the quantity", { type: "error" });
        return;
      }
      addItemToCart(item_id, parseInt(quantity))
        .then((response) => {
          if (response.data.message == "no_stock") {
            toast("Item Stock Not Availbale.", { type: "error" });
          } else {
            CountCartQty();
            toast("Item Added To Cart Successfully.", { type: "success" });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          if (error?.response?.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      toast("Please login to add item to cart", { type: "error" });
    }
  };

  const handleCartQuantity = (index, value) => {
    let flag = false;
    if (!isNaN(value) && value >= 0) {
      if (products[index].stock >= value) {
        let _product = products;
        let _item = products[index].cart;
        _item["quantity"] = value;
        _product[index]["cart"] = _item;
        setProducts([..._product]);
        flag = true;
      } else if (products[index].stock <= 0) {
        toast("Out Of Stock", { type: "error" });
      } else {
        toast(
          `Please Enter proper quantity,there are only ${products[index]?.stock} stock left.`,
          { type: "error" }
        );
      }
    } else {
      // getAllItem();
      flag = true;
      toast("Please Enter the quantity", { type: "error" });
    }
    if (!flag) {
      let _product = products;
      let _item = products[index].cart;
      _item["quantity"] = 0;
      _product[index]["cart"] = _item;
      setProducts([..._product]);
    }
  };

  const handleCartQuantityTopProduct = (index, value) => {
    let flag = false;
    if (!isNaN(value) && value >= 0) {
      if (topProducts[index].stock >= value) {
        let _product = topProducts;
        let _item = topProducts[index].cart;
        _item["quantity"] = value;
        _product[index]["cart"] = _item;
        setTopProducts([..._product]);
        flag = true;
      } else if (topProducts[index].stock <= 0) {
        toast("Out Of Stock", { type: "error" });
      } else {
        toast(
          `Please Enter proper quantity,there are only ${topProducts[index]?.stock} stock left.`,
          { type: "error" }
        );
      }
    } else {
      getAllItem();
      flag = true;
      toast("Please Enter the quantity", { type: "error" });
    }
    if (!flag) {
      let _product = topProducts;
      let _item = topProducts[index].cart;
      _item["quantity"] = 0;
      _product[index]["cart"] = _item;
      setTopProducts([..._product]);
    }
  };

  const handleCartQuantityPromotedProduct = (index, value) => {
    let flag = false;
    if (!isNaN(value) && value >= 0) {
      if (promotedItems[index].stock >= value) {
        let _product = promotedItems;
        let _item = promotedItems[index].cart;
        _item["quantity"] = value;
        _product[index]["cart"] = _item;
        setPromotedItems([..._product]);
        flag = true;
      } else if (promotedItems[index].stock <= 0) {
        toast("Out Of Stock", { type: "error" });
      } else {
        toast(
          `Please Enter proper quantity,there are only ${promotedItems[index]?.stock} stock left.`,
          { type: "error" }
        );
      }
    } else {
      getAllItem();
      flag = true;
      toast("Please Enter the quantity", { type: "error" });
    }
    if (!flag) {
      let _product = promotedItems;
      let _item = promotedItems[index].cart;
      _item["quantity"] = 0;
      _product[index]["cart"] = _item;
      setPromotedItems([..._product]);
    }
  };

  const handleCartQuantityfrequentlyOrderedItems = (index, value) => {
    let flag = false;
    if (!isNaN(value) && value >= 0) {
      if (frequentlyOrderedItems[index].stock >= value) {
        let _product = frequentlyOrderedItems;
        let _item = frequentlyOrderedItems[index].cart;
        _item["quantity"] = value;
        _product[index]["cart"] = _item;
        setFrequentlyOrderedItems([..._product]);
        flag = true;
      } else if (frequentlyOrderedItems[index].stock <= 0) {
        toast("Out Of Stock", { type: "error" });
      } else {
        toast(
          `Please Enter proper quantity,there are only ${frequentlyOrderedItems[index]?.stock} stock left.`,
          { type: "error" }
        );
      }
    } else {
      getAllItem();
      flag = true;
      toast("Please Enter the quantity", { type: "error" });
    }
    if (!flag) {
      let _product = frequentlyOrderedItems;
      let _item = frequentlyOrderedItems[index].cart;
      _item["quantity"] = 0;
      _product[index]["cart"] = _item;
      setFrequentlyOrderedItems([..._product]);
    }
  };

  const CountCartQty = () => {
    getCartItemQty()
      .then((response) => {
        setCartQty(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
        if (error?.response?.status == 422)
          toast(error.response.data.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };
  return (
    <main className="main">
      <Slider
        imageArray={silderImages}
        offerImage={singleImages?.type1?.image}
        isLoading={sliderIsloading}
        slug={singleImages?.type1?.category?.slug}
      />
      <div className=" d-xl-none ">
        <Slider2
          image={singleImages?.type1?.image}
          isLoading={singleImgIsloading}
          slug={singleImages?.type1?.category?.slug}
        />
      </div>
      {/*End hero slider*/}
      <Banner
        isLoading={singleImgIsloading}
        image1={singleImages?.type2?.image}
        image2={singleImages?.type3?.image}
        image3={singleImages?.type4?.image}
        image1Slug={singleImages?.type2?.category?.slug}
        image2Slug={singleImages?.type3?.category?.slug}
        image3Slug={singleImages?.type4?.category?.slug}
      />

      {/*End banners*/}
      <section className="product-tabs section-padding position-relative">
        <div className="container">
          <div className="section-title style-2">
            <h3>Selling Products</h3>
            <ul
              className="nav nav-tabs links"
              style={{ display: "none" }}
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="nav-tab-one"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-one"
                  type="button"
                  role="tab"
                  aria-controls="tab-one"
                  aria-selected="true"
                >
                  All
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-two"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-two"
                  type="button"
                  role="tab"
                  aria-controls="tab-two"
                  aria-selected="false"
                >
                  Milks &amp; Dairies
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-three"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-three"
                  type="button"
                  role="tab"
                  aria-controls="tab-three"
                  aria-selected="false"
                >
                  Coffes &amp; Teas
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-four"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-four"
                  type="button"
                  role="tab"
                  aria-controls="tab-four"
                  aria-selected="false"
                >
                  Pet Foods
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-five"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-five"
                  type="button"
                  role="tab"
                  aria-controls="tab-five"
                  aria-selected="false"
                >
                  Meats
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-six"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-six"
                  type="button"
                  role="tab"
                  aria-controls="tab-six"
                  aria-selected="false"
                >
                  Vegetables
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-seven"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-seven"
                  type="button"
                  role="tab"
                  aria-controls="tab-seven"
                  aria-selected="false"
                >
                  Fruits
                </button>
              </li>
            </ul>
          </div>
          {/*End nav-tabs*/}
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="tab-one"
              role="tabpanel"
              aria-labelledby="tab-one"
            >
              {!sellingProductIsLoading ? (
                <div
                  className="row product-grid-4  m-xl-5 m-lg-3 m-sm-0 "
                  // style={{ margin: "50px" }}
                >
                  {products.map((item, index) => {
                    let ele = document?.getElementById(
                      `no_right_click${index}`
                    );
                    ele?.addEventListener("contextmenu", (ev) => {
                      ev?.preventDefault(); // this will prevent browser default behavior
                    });

                    return (
                      <div
                        key={index}
                        className="col-lg-1-4 col-md-3 col-12 col-sm-6 inner-product-grid"
                      >
                        <div
                          className="product-cart-wrap mb-60"
                          // id="hover-area"
                          style={{
                            borderColor: "var(--primary-color)",
                            minHeight: "400px",
                          }}
                        >
                          <div className="product-img-action-wrap">
                            <div
                              className="product-img product-img-zoom"
                              id={`no_right_click${index}`}
                            >
                              <Link
                                to={
                                  "/product/" +
                                  item.id +
                                  "/" +
                                  convertToSlug(item.item_name) +
                                  "/" +
                                  true
                                }
                                state={{ isItem: true }}
                              >
                                <img
                                  className="default-img"
                                  style={{
                                    minHeight: "250px",
                                    maxHeight: "250px",
                                  }}
                                  src={
                                    item?.image
                                      ? process.env.REACT_APP_BASE_URL +
                                        "/storage/products/" +
                                        item.image
                                      : process.env.REACT_APP_BASE_URL +
                                        "/storage/products/no-image.png"
                                  }
                                  alt=""
                                />
                                {/* <img
                            className="hover-img"
                            src="/images/shop/product-1-2.jpg"
                            alt=""
                          /> */}
                              </Link>
                            </div>
                            <div className="product-action-1">
                              <a
                                aria-label="Add To Favourites"
                                className="action-btn"
                                href=""
                                onClick={(e) => {
                                  // toggleWishlist(item, e);
                                  wishlistToggle(item?.id, e, false);
                                }}
                              >
                                {item?.wishlist ? (
                                  <i className="wisilist-fill">&#10084;</i>
                                ) : (
                                  <i className="fi-rs-heart" />
                                )}
                              </a>
                            </div>
                          </div>
                          <div
                            className="product-content-wrap"
                            style={{ height: "80px" }}
                          >
                            <div className="product-category">
                              <Link
                                to={
                                  "/product-category/" +
                                  item.category?.slug +
                                  "/1"
                                }
                              >
                                {decodeHTMLEntities(
                                  item.category?.category_name
                                )}
                              </Link>
                            </div>
                            <h2>
                              <Link
                                to={
                                  "/product/" +
                                  item.id +
                                  "/" +
                                  convertToSlug(item.item_name) +
                                  "/" +
                                  true
                                }
                                state={{ isItem: true }}
                                className="ellipsis"
                                style={{
                                  padding: "0px",
                                  // minHeight: "60px",
                                  // minWidth: "265px",
                                }}
                              >
                                {decodeHTMLEntities(item.item_name)}
                              </Link>
                            </h2>
                            {products[index]?.stock <= 0 && (
                              <div
                                style={{
                                  textAlign: "end",
                                  marginRight: "30px",
                                }}
                              >
                                <Blink
                                  color="red"
                                  text="Out Of Stock!"
                                  fontSize="50"
                                >
                                  Out of stock!
                                </Blink>
                              </div>
                            )}
                          </div>

                          <div
                            className="product-content-wrap bg-price"
                            // style={{ paddingTop: !item?.isItem && "218px" }}
                            style={{ paddingTop: "0px" }}
                          >
                            <div className="product-card-bottom ">
                              <div className=" row">
                                <span className="product-price  ">
                                  <span>${item?.sales_price?.toFixed(2)}</span>
                                </span>
                                <span className="user-id-font">{userId}</span>

                                {/* <span className="old-price">
                                ${item.sales_price}
                              </span> */}
                              </div>

                              <div className="detail-qty group-item-qty border radius">
                                <a
                                  onClick={(e) => {
                                    if (products[index]?.stock > 0) {
                                      handleCartQuantity(
                                        index,
                                        Number(item.cart?.quantity) - 1
                                      );
                                    }
                                  }}
                                  className="qty-down"
                                >
                                  <i className="fi-rs-angle-small-down" />
                                </a>
                                <input
                                  type="text"
                                  name="cart_qty"
                                  className="qty-val"
                                  min={0}
                                  disabled={products[index]?.stock <= 0}
                                  onChange={(e) => {
                                    if (products[index]?.stock > 0) {
                                      handleCartQuantity(index, e.target.value);
                                    }
                                  }}
                                  value={item.cart?.quantity}
                                />
                                <a
                                  onClick={(e) => {
                                    if (products[index]?.stock > 0) {
                                      handleCartQuantity(
                                        index,
                                        Number(item.cart?.quantity) + 1
                                      );
                                    }
                                  }}
                                  className="qty-up"
                                >
                                  <i className="fi-rs-angle-small-up" />
                                </a>
                              </div>
                              {/* <input type='number' min={0} name="cart_qty" className="form-control cart_input" value={item.cart?.quantity} onChange={(e) => handleCartQuantity(index,e.target.value)}/> */}
                            </div>
                            <a
                              href=""
                              onClick={(e) => {
                                e?.preventDefault();
                                if (products[index]?.stock > 0) {
                                  handleAddToCart(item, e);
                                }
                              }}
                              className="btn w-100 hover-up mt-10"
                              style={{
                                padding: "8px",
                                backgroundColor:
                                  products[index]?.stock <= 0 && "gray",
                              }}
                            >
                              <i className="fi-rs-shopping-cart mr-5" />
                              Add To Cart
                            </a>
                            {/*<div className="add-cart">
                        <div style={{display:"flex",justifyContent:'right'}}>
                          <input type='text' name="cart_qty" className="form-control cart_input" value={item.cart?.quantity} onChange={(e) => handleCartQuantity(index,e.target.value)}/>
                          <a className="add" href="" onClick={(e) => handleAddToCart(item,e,index)}>
                            <i className="fi-rs-shopping-cart mr-5" />
                            Add{" "}
                          </a>
                        </div>
                      </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    minHeight: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Loader />
                  <h6 className="mt-3">It may take a few seconds to load for first time...</h6>
                </div>
              )}
              {/*End product-grid-4*/}
            </div>
            {/*En tab one*/}
          </div>
          {/*End tab-content*/}
        </div>
      </section>
      {/*Products Tabs*/}

      <section className="product-tabs section-padding position-relative">
        <div className="container">
          <div className="section-title style-2">
            <h3>Popular Products</h3>
            {/* <ul className="nav nav-tabs links" style={{display:'none'}} id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="nav-tab-one"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-one"
                  type="button"
                  role="tab"
                  aria-controls="tab-one"
                  aria-selected="true"
                >
                  All
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-two"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-two"
                  type="button"
                  role="tab"
                  aria-controls="tab-two"
                  aria-selected="false"
                >
                  Milks &amp; Dairies
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-three"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-three"
                  type="button"
                  role="tab"
                  aria-controls="tab-three"
                  aria-selected="false"
                >
                  Coffes &amp; Teas
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-four"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-four"
                  type="button"
                  role="tab"
                  aria-controls="tab-four"
                  aria-selected="false"
                >
                  Pet Foods
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-five"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-five"
                  type="button"
                  role="tab"
                  aria-controls="tab-five"
                  aria-selected="false"
                >
                  Meats
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-six"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-six"
                  type="button"
                  role="tab"
                  aria-controls="tab-six"
                  aria-selected="false"
                >
                  Vegetables
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-seven"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-seven"
                  type="button"
                  role="tab"
                  aria-controls="tab-seven"
                  aria-selected="false"
                >
                  Fruits
                </button>
              </li>
            </ul> */}
          </div>
          {/*End nav-tabs*/}
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="tab-one"
              role="tabpanel"
              aria-labelledby="tab-one"
            >
              {!topProdoctIsLoading ? (
                <div className="row product-grid-4  m-xl-5 m-lg-3 m-sm-0">
                  {topProducts.map((item, index) => {
                    let ele = document?.getElementById(
                      `no_right_click${index}`
                    );
                    ele?.addEventListener("contextmenu", (ev) => {
                      ev?.preventDefault(); // this will prevent browser default behavior
                    });
                    return (
                      <div
                        key={index}
                        className="col-lg-1-4 col-md-3 col-12 col-sm-5 inner-product-grid"
                      >
                        <div
                          className="product-cart-wrap mb-60"
                          // id="hover-area"
                          style={{
                            borderColor: "var(--primary-color)",
                            minHeight: "400px",
                          }}
                        >
                          <div className="product-img-action-wrap">
                            <div
                              className="product-img product-img-zoom"
                              id={`no_right_click${index}`}
                            >
                              <Link
                                to={
                                  "/product/" +
                                  item.id +
                                  "/" +
                                  convertToSlug(item.item_name) +
                                  "/" +
                                  true
                                }
                                state={{ isItem: true }}
                              >
                                <img
                                  className="default-img"
                                  style={{
                                    minHeight: "250px",
                                    maxHeight: "250px",
                                  }}
                                  src={
                                    item?.image
                                      ? process.env.REACT_APP_BASE_URL +
                                        "/storage/products/" +
                                        item.image
                                      : process.env.REACT_APP_BASE_URL +
                                        "/storage/products/no-image.png"
                                  }
                                  alt=""
                                />
                                {/* <img
                            className="hover-img"
                            src="/images/shop/product-1-2.jpg"
                            alt=""
                          /> */}
                              </Link>
                            </div>
                            <div className="product-action-1">
                              <a
                                aria-label="Add To Favourites"
                                className="action-btn"
                                href=""
                                onClick={(e) => {
                                  // toggleWishlist(item, e);
                                  wishlistToggleforTopProducts(
                                    item?.id,
                                    e,
                                    false
                                  );
                                }}
                              >
                                {item?.wishlist ? (
                                  <i className="wisilist-fill">&#10084;</i>
                                ) : (
                                  <i className="fi-rs-heart" />
                                )}
                              </a>
                              {/*  <a
                          aria-label="Compare"
                          className="action-btn"
                          href="shop-compare.html"
                        >
                          <i className="fi-rs-shuffle" />
                        </a>
                        <a
                          aria-label="Quick view"
                          className="action-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#quickViewModal"
                        >
                          <i className="fi-rs-eye" />
                        </a> */}
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg">
                              <span className="hot">Hot</span>
                            </div>
                          </div>
                          <div
                            className="product-content-wrap"
                            style={{ height: "80px" }}
                          >
                            <div className="product-category">
                              <Link
                                to={
                                  "/product-category/" +
                                  item.category?.slug +
                                  "/1"
                                }
                              >
                                {decodeHTMLEntities(
                                  item.category?.category_name
                                )}
                              </Link>
                            </div>
                            <h2>
                              <Link
                                to={
                                  "/product/" +
                                  item.id +
                                  "/" +
                                  convertToSlug(item.item_name) +
                                  "/" +
                                  true
                                }
                                state={{ isItem: true }}
                                className="ellipsis"
                                style={{
                                  padding: "0px",
                                  // minHeight: "60px",
                                  // minWidth: "265px",
                                }}
                              >
                                {decodeHTMLEntities(item.item_name)}
                              </Link>
                            </h2>
                            {topProducts[index]?.stock <= 0 && (
                              <div
                                style={{
                                  textAlign: "end",
                                  marginRight: "30px",
                                }}
                              >
                                <Blink
                                  color="red"
                                  text="Out Of Stock!"
                                  fontSize="50"
                                >
                                  Out of stock!
                                </Blink>
                              </div>
                            )}
                          </div>

                          <div
                            className="product-content-wrap"
                            // style={{ paddingTop: !item?.isItem && "218px" }}
                            style={{ paddingTop: "0px" }}
                          >
                            <div className="product-card-bottom">
                              <div className="row">
                                <span className="product-price">
                                  <span>${item?.sales_price?.toFixed(2)}</span>
                                  {/* <span className="old-price">
                                ${item.sales_price}
                              </span> */}
                                </span>
                                <span className="user-id-font">
                                  <span> {userId}</span>
                                </span>
                              </div>
                              <div className="detail-qty group-item-qty border radius">
                                <a
                                  onClick={(e) => {
                                    if (topProducts[index]?.stock > 0) {
                                      handleCartQuantityTopProduct(
                                        index,
                                        Number(item.cart?.quantity - 1)
                                      );
                                    }
                                  }}
                                  className="qty-down"
                                >
                                  <i className="fi-rs-angle-small-down" />
                                </a>
                                <input
                                  type="text"
                                  name="cart_qty"
                                  className="qty-val"
                                  min={0}
                                  disabled={topProducts[index]?.stock <= 0}
                                  onChange={(e) => {
                                    if (topProducts[index]?.stock > 0)
                                      handleCartQuantityTopProduct(
                                        index,
                                        e.target.value
                                      );
                                  }}
                                  value={item.cart?.quantity}
                                />
                                <a
                                  onClick={(e) => {
                                    if (topProducts[index]?.stock > 0) {
                                      handleCartQuantityTopProduct(
                                        index,
                                        Number(item.cart?.quantity + 1)
                                      );
                                    }
                                  }}
                                  className="qty-up"
                                >
                                  <i className="fi-rs-angle-small-up" />
                                </a>
                              </div>
                              {/* <input type='number' min={0} name="cart_qty" className="form-control cart_input" value={item.cart?.quantity} onChange={(e) => handleCartQuantityTopProduct(index,e.target.value)}/> */}
                              {/* <div className="add-cart">
                          <a className="add" href="" onClick={(e) => handleAddToCart(item,e)}>
                            <i className="fi-rs-shopping-cart mr-5" />
                            Add{" "}
                          </a>
                        </div> */}
                            </div>
                            <a
                              href=""
                              onClick={(e) => {
                                e?.preventDefault();
                                if (topProducts[index]?.stock > 0) {
                                  handleAddToCart(item, e);
                                }
                              }}
                              className="btn w-100 hover-up mt-10"
                              style={{
                                padding: "8px",
                                backgroundColor:
                                  topProducts[index]?.stock <= 0 && "gray",
                              }}
                            >
                              <i className="fi-rs-shopping-cart mr-5" />
                              Add To Cart
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    minHeight: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Loader />
                  <h6 className="mt-3">It may take a few seconds to load for first time...</h6>
                </div>
              )}
              {/*End product-grid-4*/}
            </div>
            {/*En tab one*/}
          </div>
          {/*End tab-content*/}
        </div>
      </section>
      {/*Products Tabs*/}

      {is_authenticated ? (
        <>
          <PromotedItems
            items={promotedItems}
            handleCartQuantityPromotedProduct={
              handleCartQuantityPromotedProduct
            }
            handleAddToCart={handleAddToCart}
          />

          <FrequentlyOrderedItems
            items={frequentlyOrderedItems}
            handleCartQuantityfrequentlyOrderedItems={
              handleCartQuantityfrequentlyOrderedItems
            }
            handleAddToCart={handleAddToCart}
          />
        </>
      ) : null}

      {/* <ProductColumns /> */}
      {/*End 4 columns*/}

      <ShopByCategory />
    </main>
  );
};

export default Home;
