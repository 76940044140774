import { useEffect, useState } from "react";
import {
  retriveNotifications,
  seenNotification,
} from "../../services/NotificationService";
import moment from "moment";
import { tz } from "moment-timezone";

const Notification = (props) => {
  const [seeAll, setSeeAll] = useState(false);
  const [notifications, setnotifications] = useState([]);
  const handleInputChange = (e) => {
    setSeeAll(e.target.checked);
  };

  useEffect(() => {
    getNotifications();
  }, [seeAll]);

  const getNotifications = async () => {
    await retriveNotifications(seeAll)
      .then((response) => {
        setnotifications(response.data.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };
  const handleNotificationSeen = async (id, index) => {
    await seenNotification(id)
      .then((response) => {
        let _notifications = [];
        notifications.map((n, i) => {
          if (i == index) {
            n.seen = true;
          }
          if (seeAll || (!seeAll && i != index)) {
            _notifications.push(n);
          }
        });
        setnotifications(_notifications);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const handleRemoveNotification = async (index) => {
    let n = notifications.filter((n, i) => i != index);
    setnotifications(n);
  };

  return (
    <div>
      <div className="fade modal-backdrop show"></div>
      <div
        aria-modal="true"
        className="fade modal show"
        style={{ display: "block" }}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title modal-title-notify">Notification</h4>
              <button
                type="button"
                onClick={props.show}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="login_footer form-group">
                  <div className="chek-form">
                    <div className="custome-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="see_all"
                        id="SeeAll"
                        checked={seeAll}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label
                        className="form-check-label see-all-label"
                        htmlFor="SeeAll"
                      >
                        <span>See All</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {notifications.length == 0 ? (
                <h5 className="no-notification">
                  You don't have any notifications.
                </h5>
              ) : null}
              <div className="notification-ui_dd-content">
                {notifications.map((notification, index) => {
                  let created_at = notification.created_at;
                  let currentTimeByEST = moment(created_at).from(
                    moment
                      .tz(new Date(), "America/New_York")
                      .format("YYYY-MM-DD HH:mm:ss")
                  );
                  return (
                    <div
                      className={
                        "notification-list " +
                        (notification.seen_customer == 0
                          ? "notification-list--unread"
                          : "")
                      }
                      key={index}
                    >
                      <div className="row">
                        <div className="col-10">
                          <div className="notification-list_content">
                            <div className="notification-list_detail">
                              <p>{notification.title}</p>
                              <p className="text-muted">{notification.desc}</p>
                              <p className="text-muted">
                                <small>{currentTimeByEST}</small>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="notification-list_feature-img">
                            <div style={{ textAlign: "end" }}>
                              <i
                                onClick={() => handleRemoveNotification(index)}
                                className="ico-times text-danger"
                                style={{ fontSize: "20px", cursor: "pointer" }}
                              ></i>
                            </div>
                            <div className="notify-action">
                              {notification.seen_customer == 0 ? (
                                <button
                                  onClick={() => {
                                    handleNotificationSeen(
                                      notification.id,
                                      index
                                    );
                                    props.count();
                                  }}
                                  className="btn btn-success btn-sm"
                                  style={{
                                    backgroundColor: "#00a65a",
                                    borderColor: "#008d4c",
                                  }}
                                >
                                  {" "}
                                  Seen{" "}
                                </button>
                              ) : (
                                ""
                              )}
                              {/* <button className="btn btn-default btn-sm" style={{ backgroundColor : '#f4f4f4', color : '#444', borderColor : '#ddd' }}>GO</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default btn-sm"
                onClick={props.show}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Notification;
