import React, { useState } from "react";

const PopupModal = ({ yesClicked }) => {
  const [noClicked, setnoClicked] = useState(false);

  let widthOfPage = window.innerWidth > 800;


  return (
    <div
      className="popup-modal"
      style={{
        backgroundColor: noClicked
          ? "var(--dark-container)"
          : "rgba(0, 0, 0, 0.8)",
      }}
    >
      <div
        className="popup-content"
        style={{
          top: "-52px",
          left: "375.5px",
          width: widthOfPage ? "500px" : "300px",
          height: widthOfPage ? "500px" : "300px",
        }}
      >
        <div
          className="popup-inside-data "
          style={{
            marginTop: widthOfPage ? "45px" : "70px",
          }}
        >
          {widthOfPage ? (
            <h3 className="">
              {noClicked ? `We're Sorry!` : "Are you 21 or older?"}
            </h3>
          ) : (
            <h6 className="">
              {noClicked ? `We're Sorry!` : "Are you 21 or older?"}
            </h6>
          )}
          {noClicked && <span>You are not allowed to enter!</span>}
          <div
            className="popup-button"
            style={{
              marginTop: widthOfPage ? "20px" : "10px",
              paddingBottom: widthOfPage ? "25px" : "5px",
            }}
          >
            {!noClicked ? (
              <>
                <button
                  className="button button-add-to-cart"
                  onClick={yesClicked}
                >
                  Yes
                </button>
                <button
                  className="button button-add-to-cart"
                  onClick={() => {
                    setnoClicked(true);
                  }}
                >
                  No
                </button>
              </>
            ) : (
              <button
                className="button button-add-to-cart"
                onClick={yesClicked}
              >
                I'm old enough
              </button>
            )}
          </div>
          <div style={{ width: !widthOfPage && "200px" }}>
            {widthOfPage ? (
              <h5>
                You must verify that you are 21 years of age or older to enter
                this site.
              </h5>
            ) : (
              <p>
                You must verify that you are 21 years of age or older to enter
                this site.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
