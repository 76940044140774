import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { lostPassword } from "../../services/AuthService";
import { randomSecurityCode, randomString } from "../../utils/constant";
import { boolean, object, string } from "yup";
import { useFormik } from "formik";
import ConfirmationPopupModal from "../ConfirmationPopupModal";

const LostPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [securityCode, setSecurityCode] = useState("");
  const [code, setCode] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [incorrectPassPopup, setincorrectPassPopup] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [confirmationPopup, setconfirmationPopup] = useState(false);

const navigate=useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
    setSecurityCode(randomSecurityCode());
  }, []);

  const AdminSchema = object().shape({
    username: string()
      .required("Please enter Username.")
      .trim("Please enter Username."),
  });

  const submitClicked = async (email) => {
    // e.preventDefault();
    if (validateInput()) {
      let token = randomString(32);
      setIsLoading(true);
      await lostPassword(email, token)
        .then((response) => {
          localStorage.setItem("pass-reset-token", token);
          // toast("Email Sent Successfully. Please Check Your Mail Inbox.", {
          //   type: "success",
          // });
          setconfirmationPopup(true);
           setincorrectPassPopup(false);
           seterrorMessage("");

        })
        .catch((error) => {
          setSecurityCode(randomSecurityCode());
          let errMsg =
            error.response.data.error ||
            error.response.data.message ||
            "Something went wrong";
          setincorrectPassPopup(true);
          seterrorMessage(errMsg);
          // if (error?.response?.status == 422)
          //   toast(error.response.data.error, { type: "error" });
          // else if (error?.response?.status == 500)
          //   toast(error.response.data.message, { type: "error" });
          // else toast("Something went wrong", { type: "error" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: AdminSchema,

      initialValues: {
        username: "",
      },
      onSubmit: async (values, { resetForm }) => {
        submitClicked(values?.username);
      },

      onReset: () => {},
    });

  const validateInput = () => {
    // if (code == "") {
    //   toast("Please enter security code.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    if (!isAgree) {
      // toast("Please accept the terms and conditions.", {
      //   type: "error",
      // });
      setincorrectPassPopup(true);
      seterrorMessage("Please accept the terms and conditions.");
      return false;
    }
    if (code != securityCode) {
      setincorrectPassPopup(true);
      seterrorMessage("Security code does not match");

      return false;
    }

    return true;
  };

  return (
    <>
      <main className="main pages">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                <i className="fi-rs-home mr-5" />
                Home
              </Link>
              <span /> Forgot Password
            </div>
          </div>
        </div>
        <div className="page-content pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-12 m-auto">
                <div className="login_wrap widget-taber-content background-white">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <img
                        className="border-radius-15"
                        src="/images/page/forgot_password.svg"
                        alt=""
                      />
                      <h2 className="mb-15 mt-15">Forgot your password?</h2>
                      <p className="mb-30">
                        Not to worry, we got you! Let’s get you a new password.
                        Please enter your your Username or Tax Id.
                      </p>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="username"
                          required=""
                          name="username"
                          placeholder="Username || Tax Id *"
                          value={values.username}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.username && errors?.username
                                ? "red"
                                : `var(--dark-container)`,
                          }}
                          // onChange={(e) => setEmail(e.target.value)}
                        />
                        {touched?.username && errors?.username && (
                          <span className="error-text">{errors.username}</span>
                        )}
                      </div>
                      <div className="login_footer form-group">
                        <div className="chek-form">
                          <input
                            type="text"
                            required=""
                            name="code"
                            placeholder="Security code *"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </div>
                        <span className="security-code">
                          {securityCode.split("").map((num, index) => {
                            return index == 0 ? (
                              <b key={index} className="text-new">
                                {num}
                              </b>
                            ) : index == 1 ? (
                              <b key={index} className="text-hot">
                                {num}
                              </b>
                            ) : index == 2 ? (
                              <b key={index} className="text-sale">
                                {num}
                              </b>
                            ) : (
                              <b key={index} className="text-best">
                                {num}
                              </b>
                            );
                          })}
                        </span>
                      </div>
                      <div className="login_footer form-group mb-50">
                        <div className="chek-form">
                          <div className="custome-checkbox">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="checkbox"
                              id="exampleCheckbox12"
                              checked={isAgree}
                              onChange={(e) =>
                                e.target.checked
                                  ? setIsAgree(true)
                                  : setIsAgree(false)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheckbox12"
                            >
                              <span>I agree to terms &amp; Policy.</span>
                            </label>
                          </div>
                        </div>
                        <Link to="/privacy-policy">
                          <i className="fi-rs-book-alt mr-5 text-muted" />
                          Lean more
                        </Link>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          disabled={isLoading}
                          className="btn btn-heading btn-block hover-up"
                          name="login"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Reset password
                          {isLoading ? (
                            <img
                              style={{ marginLeft: "10px" }}
                              src="/images/loader.gif"
                              width="25"
                              alt="loader"
                            />
                          ) : null}
                        </button>
                      </div>
                    </form>
                  </div>
                  <ConfirmationPopupModal
                    // title={"Customer Registered Successfully"}
                    content={"Email Sent Successfully. Please Check Your Mail Inbox."}
                    show={confirmationPopup}
                    isOk={true}
                    // isRegister={true}
                    setShow={setconfirmationPopup}
                    confirmClicked={(e) => {
                      navigate("/login");
                    }}
                  />
                  <ConfirmationPopupModal
                    title={"ERROR"}
                    content={`${errorMessage}`}
                    show={incorrectPassPopup}
                    isOk={true}
                    setShow={setincorrectPassPopup}
                    confirmClicked={(e) => {
                      setincorrectPassPopup(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LostPassword;
