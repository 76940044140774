import { Link } from "react-router-dom";

const Nointernet = (isOffline) => {
  return (
    <main className="main page-404">
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
              <p className="mb-20">
                <img
                  src="/images/page/no-internet.jpg"
                  alt="no-internet-img"
                  className="hover-up"
                />
              </p>

              <h1 className="display-2 mb-30">
                Please check your internet connection
              </h1>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Nointernet;
