import HeaderBottom from "./HeaderBottom";
import HeaderMiddle from "./HeaderMiddle";
import { useEffect, useContext, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { retriveAccountDetails } from "../../services/CustomerService";
import { AppContext } from "../../utils/AppProvider";
import ConfirmationPopupModal from "../ConfirmationPopupModal";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";

const Header = ({ signOutClicked }) => {
  const { is_authenticated } = useContext(AppContext);

  const [showAnnouncement, setShowAnnouncement] = useState(false);
  let widthOfPage = window.innerWidth;
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = useCallback(() => {
    if (is_authenticated) {
      retriveAccountDetails()
        .then((response) => {
          if (response?.data?.data) {
            let data = response.data.data;

            if (data.status !== "approved") {
              setShowAnnouncement(true);
            }
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }, [showAnnouncement]);

  return (
    <>
      {showAnnouncement ? (
        <div
          className="text-center position-relative px-4 py-1"
          style={{ backgroundColor: "#000000", color: "#ffffff" }}
        >
          <span>
            You are not approved, please wait for 48 hours or
            contact administrator{" "}
          </span>
          <span className="__close-anouncement"></span>
        </div>
      ) : (
        ""
      )}

      <header className="header-area header-style-1 header-height-2 ">
        <HeaderMiddle signOutClicked={signOutClicked} />
      </header>
      <div
        className="header-area header-style-1 header-height-2"
        // style={{ paddingTop: widthOfPage > 1200 && "90px" }}
      >
        <HeaderBottom
          signOutClicked={signOutClicked}
          isSticky={widthOfPage < 1200}
        />
      </div>
    </>
  );
};

export default Header;
